import { IconButton } from '@scalingworks/react-admin-ui';
import React, { useContext } from 'react';
import { IoPrintSharp } from 'react-icons/io5';
import { PrinterContext } from '~/providers/Printer/context';

export const ConnectPrinter: React.FC = () => {
  // ====================== HOOKS

  const { printerRef } = useContext(PrinterContext);

  // ====================== EVENTS
  const onConnectPrinter = () => {
    printerRef.current.connect();
  };

  // ====================== VIEWS
  return (
    <IconButton onClick={onConnectPrinter}>
      <IoPrintSharp size={25} className="!text-gray-400" />
    </IconButton>
  );
};
