import { Button, Dialog, SelectField, Tag } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustomMutation, useGetIdentity, useTranslate } from '@refinedev/core';
import {
  CreateRedemptionHistoryInput,
  RedeemerRole,
  RedemptionHistoryType,
  RewardType,
} from '~/api';
import { getChannel, toCamelCaseWord } from '~/resources/helpers';
import { PromoCodeSelection, RewardSelection } from '~/components/SearchAndSelect';
import { VendureUserIdentity } from '@scalingworks/refine-react-admin/src/types';

type Redemption = {
  type: RedemptionHistoryType;
  promoCodeId?: string;
  rewardId?: string;
};

export const RedeemReward: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    userId,
    customerName,
    pointBalance = 0,
    onCompleted,
    currentTierId,
  } = props;

  // ==================== HOOKS
  const apiUrl = useApiUrl();
  const t = useTranslate();
  const identity = useGetIdentity<VendureUserIdentity>();

  // ==================== STATES
  const [redemption, setRedemption] = useState<Redemption | null>();
  const [pointCost, setPointCost] = useState(0);

  // ==================== VARIABLES
  const insufficientPoints = pointCost > pointBalance;
  const isAdmin = getChannel(identity?.data!)?.permissions.includes('SuperAdmin');
  const staffUserId = identity?.data?.me?.id;

  // ==================== API
  const { mutate: redeem, isLoading: updating } = useCustomMutation();

  const onRedeem = () => {
    if (!userId || !staffUserId) {
      // not suppose to happen
      console.warn(`[Error]: Invalid user id`);
      return;
    }

    redeem(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'redeemForCustomer',
          variables: {
            input: {
              type: 'CreateRedemptionHistoryInput!',
              value: {
                membershipUserId: userId,
                redeemerId: staffUserId,
                redeemerRole: isAdmin ? RedeemerRole.Admin : RedeemerRole.Staff,
                promoCodeId: redemption?.promoCodeId,
                rewardId: redemption?.rewardId,
              } as CreateRedemptionHistoryInput,
            },
          },
        },
        successNotification: {
          message: t('customer.loyalty.redeemSuccess', {}, 'Item redeemed'),
          type: 'success',
        },
        errorNotification: {
          message: t('customer.loyalty.redeemFailed', {}, 'Failed to redeem item'),
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: () => {
          setOpen(false);
          onCompleted?.();
        },
      }
    );
  };

  const getDisabled = () => {
    return !redemption || !(redemption.promoCodeId || redemption.rewardId) || insufficientPoints;
  };

  const renderReward = () => {
    const type = redemption?.type;

    if (type === RedemptionHistoryType.PromoCode) {
      return (
        // TODO: backend filter by tierId
        <PromoCodeSelection
          isField
          fieldLabel={t('customer.loyalty.code', {}, 'Voucher Code')}
          onSelect={(result) => {
            const [idVal, pointVal] = result?.split('-space-');
            setPointCost(pointVal);
            // @ts-ignore
            setRedemption((prev) => ({ ...prev, promoCodeId: idVal }));
          }}
          value={redemption?.promoCodeId || ''}
          queryKey="getPromoCodes"
        />
      );
    }

    // Separate to allow re-render when type switch
    if (type === RedemptionHistoryType.InStoreItem) {
      return (
        // TODO: Backend filter by tierId input
        <RewardSelection
          key={type}
          isField
          fieldLabel={t('customer.loyalty.reward', {}, 'Reward')}
          onSelect={(result) => {
            const [idVal, pointVal] = result?.split('-space-');
            setPointCost(pointVal);
            // @ts-ignore
            setRedemption((prev) => ({ ...prev, rewardId: idVal }));
          }}
          value={redemption?.rewardId || ''}
          queryKey="getRewards"
          variables={{ type: RewardType.InStore, customerTierIds: [currentTierId] }}
        />
      );
    }

    if (type === RedemptionHistoryType.InAppItem) {
      return (
        <RewardSelection
          key={type}
          isField
          fieldLabel={t('customer.loyalty.reward', {}, 'Reward')}
          onSelect={(result) => {
            const [idVal, pointVal] = result?.split('-space-');
            setPointCost(pointVal);
            // @ts-ignore
            setRedemption((prev) => ({ ...prev, rewardId: idVal }));
          }}
          value={redemption?.rewardId || ''}
          queryKey="getRewards"
          variables={{ type: RewardType.InApp, customerTierIds: [currentTierId] }}
        />
      );
    }

    return null;
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="fixed top-1/3 lg:w-1/2"
          onFocus={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
          }}
        >
          <Dialog.Title>{t('customer.loyalty.redeemRewards', {}, 'Redeem Rewards')}</Dialog.Title>
          <div className="flex flex-row items-center justify-between px-4 pt-2">
            <h3>
              {t('customer.loyalty.redeemFor', {}, 'Redeem for: ')} {customerName}
            </h3>
            {pointBalance && (
              <div className="">
                <Tag color="purple" className="!text-sm">
                  {t('customer.loyalty.availablePoints', {}, 'Available Points:')} {pointBalance}
                </Tag>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-4 p-4">
            <SelectField
              label={t('customer.loyalty.redeemType', {}, 'Type')}
              options={Object.keys(RedemptionHistoryType).map((key) => ({
                label: toCamelCaseWord(key),
                value: RedemptionHistoryType[key as keyof typeof RedemptionHistoryType],
              }))}
              placeholder={t('customer.placeholder.type', {}, 'Select reward type')}
              value={redemption?.type || ''}
              onValue={(type) => {
                if (type) {
                  setRedemption({ type });
                }
              }}
            />

            {renderReward()}
            {insufficientPoints && (
              <span className="text-error-300 w-full text-right">
                {t('customer.loyalty.insufficientPoints', {}, 'Insufficient Points')}
              </span>
            )}
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button variant="default" onClick={() => setOpen(false)}>
              {t('actions.cancel', {}, 'Confirm')}
            </Button>
            <Button disabled={getDisabled()} loading={updating} variant="solid" onClick={onRedeem}>
              {t('actions.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
