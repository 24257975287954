import { createContext } from 'react';

export type PrinterContextProps = {
  printer?: any;
  setPrinter: (printer: any) => void;
  printerRef?: any;
};

export const PrinterContext = createContext<PrinterContextProps>({
  printer: undefined,
  setPrinter: () => null,
  printerRef: null,
});
