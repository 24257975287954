import type { DataProvider, MetaQuery } from '@refinedev/core';
import { ExtraMeta } from '@scalingworks/refine-react-admin/src/types';
import * as gql from 'gql-query-builder';
import camelCase from 'lodash/camelCase';
import head from 'lodash/head';
import startCase from 'lodash/startCase';
import { plural, singular } from 'pluralize';

import { generateFilter, generateSort, gqlInputName } from './helper';
import { GQLClient } from './gql-client';
import { redemptionHistoriesCustomGetList } from './CustomFetcher/redemption-histories';
import { resourceNames } from '~/resources/resource-names';
import { pointTransactionHistoriesCustomGetList } from './CustomFetcher/point-transaction-histories';

export const createDataProvider = (
  resourceDataProvider: Map<string, any>,
  dataLoader: unknown
): DataProvider => {
  const client = GQLClient.getInstance();

  return {
    getList: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.getList;

      // NOTE: temporary solution because the listing component is used in customer show page
      // But didn't include the resource entirely as side menu.
      // Foresee future will have more cases like this.
      // TODO: Should refactor to be able to scale easily
      if (info.resource === resourceNames.redemptionHistory) {
        return redemptionHistoriesCustomGetList({ ...info, client });
      }
      if (info.resource === resourceNames.pointTransaction) {
        return pointTransactionHistoriesCustomGetList({ ...info, client });
      }

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, filters, pagination, sort, metaData: rawMetaData } = info;
      const metaData = rawMetaData as MetaQuery & ExtraMeta;
      const defaultFilter = metaData?.defaultFilter || {};

      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 25;
      const singularResource = singular(startCase(resource))?.replaceAll(' ', '');
      const operation = metaData?.operation ?? camelCase('get-' + plural(resource));

      const { vendureFilter, search } = generateFilter(filters);

      try {
        const { query, variables } = gql.query({
          operation,
          variables: {
            options: {
              value: {
                skip: (current - 1) * pageSize,
                take: pageSize,
                sort: generateSort(sort),
                ...(vendureFilter && {
                  filter: vendureFilter,
                }),
              },
              type: gqlInputName.listOption(singularResource),
            },
            ...(search ? { search } : {}),
            ...head(
              Object.keys(defaultFilter).map((filter) => ({
                [filter]: {
                  value: defaultFilter[filter].value,
                  type: defaultFilter[filter].type || 'String!',
                },
              }))
            ),
          },
          fields: [
            {
              items: metaData?.fields ?? ['id'],
            },
            'totalItems',
          ],
        });

        const data = await client.request(query, variables);

        return {
          data: data[operation]?.items,
          total: data[operation]?.totalItems,
          metadata: {},
        };
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    getOne: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.getOne;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, id, metaData } = info;
      const operation = metaData?.operation ?? camelCase(singular(resource));

      const { query, variables } = gql.query({
        operation,
        variables: {
          id: {
            name: 'id',
            type: 'ID!',
            value: id,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const data = await client.request(query, variables);

      return {
        data: data[operation],
      };
    },
    create: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.create;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }
      const { resource, variables, metaData } = info;

      const singularResource = singular(resource);
      const operation = metaData?.operation ?? camelCase(`create-${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          input: {
            value: variables,
            type: gqlInputName.create(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const data = await client.request(query, gqlVariables);

      return {
        data: data[operation],
      };
    },
    createMany: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.createMany;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, variables, metaData } = info;

      const singularResource = singular(resource);
      const pluralResource = plural(singularResource);
      const operation = metaData?.operation ?? camelCase(`create-${pluralResource}`);

      const response = await Promise.all(
        variables.map(async (param) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: param,
                // e.g. [CreateProductInput!]!
                type: gqlInputName.createMany(singularResource),
                required: true,
              },
            },
            fields: metaData?.fields ?? ['id'],
          });

          const data = await client.request(query, gqlVariables);

          return data[operation];
        })
      );
      return {
        data: response,
      };
    },
    update: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.update;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }
      const { resource, id, variables, metaData } = info;
      const singularResource = singular(resource);
      const operation = metaData?.operation ?? camelCase(`update-${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          input: {
            value: { ...variables, ...(id ? { id } : {}) },
            // e.g. UpdateProductInput!
            type: gqlInputName.update(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },
    updateMany: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.updateMany;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, ids, variables: rawVar, metaData } = info;
      const variables = rawVar as any[];
      const singularResource = singular(resource);
      const pluralResource = plural(singularResource);
      const operation = metaData?.operation ?? camelCase(`update-${pluralResource}`);

      const response = await Promise.all(
        ids.map(async (id, index) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { id, ...variables[index] },
                type: gqlInputName.updateMany(singularResource),
                required: true,
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const data = await client.request(query, gqlVariables);

          return data[operation];
        })
      );
      return {
        data: response,
      };
    },
    deleteOne: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.deleteOne;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, id, metaData } = info;
      const singularResource = singular(resource);
      const operation = metaData?.operation ?? camelCase(`delete-${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          id: {
            value: id,
            type: 'ID!',
          },
        },
        fields: metaData?.fields ?? ['result', 'message'],
      });

      const data = await client.request(query, variables);

      return {
        data: data[operation],
      };
    },
    deleteMany: async (info) => {
      const dataProvider = resourceDataProvider.get(info.resource);
      const customFetcher = dataProvider?.deleteMany;

      if (customFetcher) {
        return customFetcher({
          ...info,
          client,
          dataLoader,
        }) as Promise<any>;
      }

      const { resource, ids, metaData } = info;
      const singularResource = singular(resource);
      const pluralResource = plural(singularResource);
      const camelDeleteName = camelCase(`delete-${pluralResource}`);

      const operation = metaData?.operation ?? camelDeleteName;

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          ids: {
            value: ids,
            type: `[ID!]!`,
          },
        },
        fields: metaData?.fields ?? ['result', 'message'],
      });

      const data = await client.request(query, gqlVariables);

      return {
        data: data[operation],
      };
    },
    getApiUrl: () => {
      return GQLClient.apiUrl;
    },
    custom: async (info) => {
      const { metaData, method } = info;

      if (metaData) {
        if (metaData.operation) {
          if (method === 'get') {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await client.request({ document: query, variables });

            return {
              data: response?.[metaData.operation] || {},
            };
          } else {
            const { query, variables } = gql.mutation({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await client.request({ document: query, variables });

            return {
              data: response[metaData.operation] || {},
            };
          }
        } else {
          throw Error('GraphQL operation name required.');
        }
      } else {
        throw Error('GraphQL need to operation, fields and variables values in metaData object.');
      }
    },
  };
};
