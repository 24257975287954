import React, { useState, useEffect } from 'react';
import { Channel, StockLocation, getSdk } from '~/api';
import { GQLClient, GQLClientHeader } from '~/config/gql-client';
import { authStorageKey, currentChannelToken } from '~/config/auth-provider';
import { ChannelContext } from './context';
import {
  currencyForColHeader as currencyForColHeaderHelper,
  onlyOneCurrency,
} from '~/resources/helpers';
import { SomethingWentWrong } from '~/components';
import isEmpty from 'lodash/isEmpty';

export const ChannelContextProvider = React.memo((props: any) => {
  const { children } = props;

  // ===================== STATES
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<Channel | null>(null);
  // separate stockLocation into different provider if needed
  const [stockLocations, setStockLocations] = useState<StockLocation[]>([]);
  const [error, setError] = useState<string | undefined>();

  // ===================== VARIABLES
  const client = GQLClient.getInstance();

  // ===================== EVENTS
  const fetchRelatedData = async () => {
    try {
      // === Channel
      const channelRes = await getSdk(client).activeChannel();
      if (channelRes.activeChannel) {
        setChannel(channelRes.activeChannel as Channel);
      }

      // === StockLocation for the channel
      const stockLocationRes = await getSdk(client).StockLocations();
      if (stockLocationRes?.stockLocations?.items) {
        setStockLocations(stockLocationRes?.stockLocations?.items as StockLocation[]);
      }
    } catch (err: any) {
      console.error(err);
      setError(err?.message || 'Failed to setup channel');
    } finally {
      setLoading(false);
    }
  };

  const fetchChannel = (token?: string) => {
    const auth = localStorage.getItem(authStorageKey);
    const channelToken = token || localStorage.getItem(currentChannelToken);

    setLoading(true);
    if (!channelToken || !auth) {
      console.error(`Unauthorized to get active channel`);
      setLoading(false);
      return;
    }

    // TODO: check why previous function call `changeChannel` already set
    // but `client` here request won't have the header, need to set again to be safe
    client.setHeader(GQLClientHeader.authorization, 'Bearer ' + auth);
    client.setHeader(GQLClientHeader.vendureToken, channelToken);

    // This is async, avoid having other execution after it
    fetchRelatedData();
  };

  const changeChannel = (token: string) => {
    client.setHeader(GQLClientHeader.vendureToken, token);
    localStorage.setItem(currentChannelToken, token);
    fetchChannel(token);

    // TODO: enhancement
    // check for more seamless refresh to re-call apis.
    window?.location?.reload();
  };

  const currencyForColHeader = () => {
    return currencyForColHeaderHelper(channel);
  };

  // ===================== EFFECTS
  useEffect(() => {
    if (!channel && !error) {
      fetchChannel();
    }
  }, [fetchChannel, channel]);

  // ===================== VIEWS
  return (
    <ChannelContext.Provider
      value={{
        loading,
        error,
        channel,
        changeChannel,
        stockLocations,
        onlyOneCurrency: onlyOneCurrency(channel),
        currencyForColHeader,
      }}
    >
      {!isEmpty(error) ? <SomethingWentWrong /> : children}
    </ChannelContext.Provider>
  );
});
