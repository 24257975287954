import { ResourceField } from '@scalingworks/refine-react-admin';
import { Membership } from '~/api';

export const getMembershipFields: ResourceField<Membership>[] = [
  'id',
  'firstName',
  'lastName',
  'starBalance',
  'pointEarned',
  'pointBalance',
  'pointRequired',
  'periodEndAt',
  'pointValidUntil',
  {
    tierProgress: [
      'description',
      'starToNextTier',
      'isHighestTier',
      { currentTier: ['id', 'name'] },
      { nextTier: ['id', 'name'] },
    ],
  },
] as ResourceField<Membership>[];
