import { getSdk } from '~/api';
import { FetcherOptions } from '.';
import { generateSort } from '../helper';

export const pointTransactionHistoriesCustomGetList = (options: FetcherOptions) => {
  const { client, filters, metaData, pagination, sort } = options;
  const current = pagination?.current || 1;
  const pageSize = pagination?.pageSize || 25;

  const tabListFilter = filters?.find((subItem: any) => subItem?.key === 'listingTab');
  const searchFilter = filters?.find((subItem: any) => subItem?.field === 'search');
  const createdAtBegin = filters?.find(
    (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'gte'
  );
  const createdAtEnd = filters?.find(
    (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'lte'
  );
  const source = filters?.find(
    (subItem: any) => subItem?.field === 'source' && subItem?.operator === 'eq'
  );

  const tabFilter =
    (tabListFilter?.value?.[0]?.value === 'ALL' ? undefined : tabListFilter?.value?.[0]?.value) ||
    undefined;

  return getSdk(client)
    ?.getPointTransactionHistories({
      input: {
        search: searchFilter?.value,
        source: tabFilter || source?.value,
        startingPointDate: createdAtBegin?.value,
        endPointDate: createdAtEnd?.value,
        userId: metaData?.userId,
      },
      options: {
        skip: (current - 1) * pageSize,
        take: pageSize,
        sort: generateSort(sort),
      },
    })
    ?.then((res) => ({
      getPointTransactionHistories: {
        items: res?.getPointTransactionHistories?.items,
        totalItems: res?.getPointTransactionHistories?.totalItems,
      },
      data: res?.getPointTransactionHistories?.items,
      total: res?.getPointTransactionHistories?.totalItems,
    }));
};
