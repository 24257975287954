import React from 'react';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { AuditLog, Channel, UserActionType } from '~/api';
import { dateFormatter, formatFullName, toCamelCaseWord } from '~/resources/helpers';
import { FullDateTimeFormat } from '~/config/constant';
import { BsClockHistory } from 'react-icons/bs';
import { resourceNames } from '../resource-names';
import { ResourceContext } from '../type';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<AuditLog>({
    resourceName: resourceNames.auditLog,
  });

const fields: ResourceField<AuditLog>[] = [
  'id',
  'title',
  'remarks',
  'userAction',
  'createdAt',
  { administrator: ['firstName', 'lastName', 'emailAddress'] },
];

export const auditLogResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames?.auditLog,
    label: 'Audit Logs',
    icon: <BsClockHistory />,
    fields: defineFields(fields),
    defaultValues: {} as any,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'id', order: 'desc' }],
    allowCreate: false,
    filterControls: {
      title: {
        type: 'text',
        config: {
          label: 'Title',
          placeholder: 'Search title',
        },
        operator: 'contains',
      },
      remarks: {
        type: 'text',
        config: {
          label: 'Remarks',
          placeholder: 'Search remarks',
        },
        operator: 'contains',
      },
      userAction: {
        type: 'select',
        config: {
          label: 'Type',
          options: Object.keys(UserActionType).map((key) => ({
            label: toCamelCaseWord(key),
            value: UserActionType[key as keyof typeof UserActionType],
          })),
          placeholder: 'Select type',
        },
        operator: 'contains',
      },
      createdAt: {
        type: 'daterange',
        config: {
          label: 'Date / Time',
        },
      },
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
      return [
        {
          id: 'user',
          header: t('auditLogs.column.user', { fallback: 'User', ns: 'common' }),
          cell: (data) => {
            const { id, administrator } = data.row.original;
            return (
              <div>
                <span>{formatFullName(administrator?.firstName, administrator?.lastName)}</span>
              </div>
            );
          },
        },
        {
          id: 'name',
          header: t('auditLogs.column.title', { fallback: 'Title', ns: 'common' }),
          cell: (data) => {
            const { id, title } = data.row.original;
            return (
              <div>
                <span>{title}</span>
              </div>
            );
          },
        },
        {
          id: 'remarks',
          header: t('auditLogs.column.remarks', { fallback: 'Remarks', ns: 'common' }),
          cell: (data) => {
            const { id, remarks } = data.row.original;
            return (
              <div>
                <span>{remarks || '-'}</span>
              </div>
            );
          },
        },
        {
          id: 'type',
          header: t('auditLogs.column.type', { fallback: 'Type', ns: 'common' }),
          cell: (data) => {
            const { id, userAction } = data.row.original;
            return (
              <div>
                <span>
                  {t(`auditLogs.type.${userAction?.toLowerCase()}`, {
                    fallback: userAction,
                    ns: 'common',
                  })}
                </span>
              </div>
            );
          },
        },
        {
          id: 'createdAt',
          header: t('auditLogs.column.dateAndTime', { fallback: 'Date / Time', ns: 'common' }),
          cell: (data) => {
            const { id, createdAt } = data.row.original;
            return (
              <div>
                <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>
              </div>
            );
          },
        },
      ];
    },
  });
};
