import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, MultiTextField, TextField } from '@scalingworks/react-admin-ui';
import { RiDeleteBin5Line } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { Controller } from 'react-hook-form';
import uniq from 'lodash/uniq';
import { formatBackendPrice } from '~/resources/helpers/product-helper';
import some from 'lodash/some';
import { Divider } from '../Divider';
import { ProductOptionGroupFormInput, ProductVariantFormInput, Props, RefProps } from './props';
import { renderInvisibleId } from './config';
import { VariantTable, VariantTableProps } from './VariantTable';
import { useTranslate } from '@refinedev/core';
import { StockLevelFormInput } from '../StockLevelForm/props';

export const VariantForm = forwardRef<RefProps, Props>((props, ref) => {
  const {
    registerKey,
    register,
    setValue,
    data,
    isUpdate,
    items,
    control,
    reset,
    watch,
    forceRerender,
    isCreatedNoVariant,
  } = props;

  const [productOptionGroups, setProductOptionGroups] = useState<
    ProductOptionGroupFormInput[] | undefined
  >([]);
  const [hasEmptyOptionsOverall, setHasEmptyOptionsOverall] = useState(false);
  const [productVariants, setProductVariants] = useState<ProductVariantFormInput[]>();
  const variantData = data?.variants;
  const optionGroupData = data?.optionGroups;
  const t = useTranslate();

  useEffect(() => {
    if (isUpdate && data) {
      setProductOptionGroups(
        optionGroupData?.map((subItem) => {
          return {
            name: subItem?.name,
            id: subItem?.id,
            options: subItem?.options?.map((subSubItem) => ({
              name: subSubItem?.name,
              id: subSubItem?.id,
            })),
          };
        })
      );
      forceRerender && forceRerender();
    }
  }, [isUpdate, data, variantData, optionGroupData]);

  useEffect(() => {
    if (isUpdate && data) {
      setProductVariants(
        variantData?.map((subItem) => {
          return {
            id: subItem?.id,
            name: subItem?.name,
            price: `${formatBackendPrice({ price: subItem?.price, isDivide: true })}`,
            stockLevels: subItem.stockLevels.map(
              (level) =>
                ({
                  stockLocationId: level.stockLocationId,
                  stockLocationName: level.stockLocation.name,
                  stockOnHand: level.stockOnHand,
                } as StockLevelFormInput)
            ),
            enabled: subItem?.enabled,
            image: !isEmpty(subItem?.assets) ? [subItem?.assets?.[0]?.source] : undefined,
            options: subItem.options?.map?.((option) => option.name),
          };
        })
      );
    }
  }, []);

  // NOTE: to patch the form to keep the 'options'
  useEffect(() => {
    setValue?.('variants', productVariants);
  }, [productVariants]);

  useImperativeHandle(ref, () => ({
    get setProductVariants() {
      return setProductVariants;
    },
    // You can add more functions or values here as needed
  }));

  const handleAddOptions = () => {
    forceRerender && forceRerender();
    setProductOptionGroups((prev) => {
      const commonVal = {
        id: undefined,
        name: '',
        options: [],
      };
      if (prev) return [...prev, commonVal];
      return [commonVal];
    });
  };

  const handleAddVariant = (options: ProductOptionGroupFormInput[]) => {
    let tmpVariants: { name: string; id?: string; options?: string[] }[] = [];
    const emptyOptionGroups = isEmpty(options);
    const hasEmptyOptions = some(options, (obj) => isEmpty(obj.options));
    setHasEmptyOptionsOverall(hasEmptyOptions);
    if (isCreatedNoVariant && (emptyOptionGroups || hasEmptyOptions)) {
      setProductVariants(
        variantData?.map((subItem) => {
          return {
            id: subItem?.id,
            name: subItem?.name,
            price: `${formatBackendPrice({ price: subItem?.price, isDivide: true })}`,
            stockLevels: [],
            enabled: subItem?.enabled,
          };
        })
      );
      reset((formValues) => ({
        ...formValues,
        variants: variantData,
      }));
      return;
    }
    if (hasEmptyOptions) return;

    // A loop function to generate a variant name
    const generateVariants = (
      arr: any[],
      currentIndex = 0,
      currentVariant = '',
      variants = [] as { name: string; id?: string; options?: string[] }[],
      id?: string,
      optionName = ''
    ) => {
      if (currentIndex === arr.length) {
        variants.push({
          name: currentVariant?.trim(),
          id,
          options: optionName
            ?.trim()
            ?.split('-split-')
            ?.filter((subItem) => !isEmpty(subItem)),
        });
        return;
      }
      const parent = arr[currentIndex];

      for (const option of parent.options) {
        if (isEmpty(option.name)) return;
        const variant = currentVariant + ' ' + option.name;
        const tmpOptionName = currentVariant + '-split-' + option.name;
        generateVariants(arr, currentIndex + 1, variant, variants, id, tmpOptionName);
      }
    };
    generateVariants(options, 0, '', tmpVariants);

    // Filtered out if there's any empty string name in the array
    const filteredArray = tmpVariants.filter((item) => item?.name !== '');

    // Set the productVariants state
    let finalVariants: ProductVariantFormInput[] = [];
    setProductVariants((prev) => {
      if (!isEmpty(filteredArray)) {
        return filteredArray?.map((subItem): ProductVariantFormInput => {
          // Check if the in the "prev", if there's any existing value. if there is, use that value
          const foundObject = prev?.find((obj) => obj.name === `${watch('name')} ${subItem?.name}`);
          if (!isEmpty(foundObject)) {
            finalVariants = [...finalVariants, foundObject];
            return foundObject;
          }

          const newValue = {
            name: `${watch('name')} ${subItem?.name}`,
            enabled: true,
            price: '',
            stockLevels: [],
            id: undefined,
            options: subItem.options,
          };
          finalVariants = [...finalVariants, newValue];
          return newValue;
        });
      }
      return undefined;
    });

    reset((formValues) => ({
      ...formValues,
      variants: finalVariants,
    }));
  };

  const renderOptionsField = () => {
    return productOptionGroups?.map((optionItem, index) => {
      const optionRegisterKey = `optionGroups.${index}`;
      const handleDeleteOptionGroups = () => {
        forceRerender && forceRerender();
        setProductOptionGroups((prev) => {
          if (!prev) return;
          const newArr = [...prev];
          newArr?.splice(index, 1);
          handleAddVariant(newArr);
          reset((formValues) => ({
            ...formValues,
            optionGroups: newArr,
          }));
          return newArr;
        });
      };

      const controllerProps = (key: string) => ({
        control,
        name: `${optionRegisterKey}.${key}`,
        // @ts-ignores
        defaultValue: optionItem[key],
      });

      return (
        <div className="flex flex-row gap-4">
          {renderInvisibleId({
            key: `${optionRegisterKey}`,
            control,
            defaultValue: optionItem['id'],
          })}
          <div className="flex-1">
            <Controller
              {...controllerProps('name')}
              render={() => {
                return (
                  <TextField
                    label={t('product.options.option')}
                    layout="vertical"
                    disabled={isUpdate}
                    value={optionItem['name']}
                    onChange={(events) => {
                      setProductOptionGroups((prev) => {
                        const updatedOptions = [...(prev || [])] as ProductOptionGroupFormInput[];
                        updatedOptions[index]['name'] = events?.target?.value;
                        return updatedOptions;
                      });
                      setValue && setValue(`${optionRegisterKey}.name`, events?.target?.value);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="flex-1">
            <Controller
              {...controllerProps(`options`)}
              render={() => {
                return (
                  <MultiTextField
                    label={t('product.options.values')}
                    layout="vertical"
                    value={optionItem['options']?.map((subItem) => subItem?.name)}
                    placeholder=""
                    type="textarea"
                    disabled={isEmpty(optionItem['name']) || isUpdate}
                    onValue={(val) => {
                      // Make sure that the tag value is unique
                      const uniqueVal = uniq(val);

                      setProductOptionGroups((prev) => {
                        const updatedOptions = [...(prev || [])] as ProductOptionGroupFormInput[];
                        const combinedArray = uniqueVal.map((name) => {
                          const matchingObj = optionItem['options'].find(
                            (obj) => obj.name === name
                          );
                          return matchingObj
                            ? { name: matchingObj.name, id: matchingObj.id }
                            : { name };
                        });
                        updatedOptions[index]['options'] = combinedArray;
                        setValue && setValue(`${optionRegisterKey}.options`, combinedArray);
                        handleAddVariant(updatedOptions);
                        return updatedOptions;
                      });
                    }}
                  />
                );
              }}
            />
          </div>
          <div>
            {!isUpdate && (
              <button
                type="button"
                onClick={() => {}}
                style={{ borderWidth: 1 }}
                className="mt-6 border-red-500 p-1 rounded"
              >
                <RiDeleteBin5Line color="red" size={20} onClick={handleDeleteOptionGroups} />
              </button>
            )}
          </div>
        </div>
      );
    });
  };

  const variantTableProps: VariantTableProps = {
    control,
    data: variantData,
    productName: watch('name'),
    items,
    productVariants,
    registerKey,
    setProductVariants,
    setValue,
    forceRerender,
    watch,
  };

  return (
    <div>
      <div className="flex-1">
        <Button
          className={`w-full ${!isEmpty(productVariants) && 'mb-6'}`}
          onClick={handleAddOptions}
          disabled={isUpdate}
        >
          {t('product.options.add')}
        </Button>
      </div>

      {!isEmpty(productOptionGroups) && (
        <>
          <Divider style={{ marginBottom: 16 }} />
          {renderOptionsField()}
        </>
      )}
      {!isEmpty(productVariants) && (
        <div className="mt-4">
          <Divider />
          <VariantTable {...variantTableProps} />
        </div>
      )}
    </div>
  );
});
