import he from 'he'
import lowerCase from 'lodash/lowerCase';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import dayjs from 'dayjs';
import { PointTransactionSourceType } from '~/api';
import compact from 'lodash/compact';
import join from 'lodash/join';

export const toCamelCaseWord = (value: string) => {
  return startCase(lowerCase(value));
};

export const removeUnderscore = (value: string) => {
  return replace(value, /_/g, ' ');
};

export const dateFormatter = (date?: any, format?: string, placeholder?: string) => {
  const useFormat = format || 'DD MMM YYYY';
  const usePlaceholder = placeholder || '-';
  if (dayjs(date).isValid()) return dayjs(date).format(useFormat);
  else return usePlaceholder;
};

export const formatFullName = (firstName?: string, lastName?: string) => {
  return firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
};

export const adjustmentSource = (source: PointTransactionSourceType) => {
  const adjustmentTranslation = {
    [PointTransactionSourceType?.Deduct]: 'Deduction',
    [PointTransactionSourceType?.Grant]: 'Granted',
    [PointTransactionSourceType?.Earned]: 'Earned',
    [PointTransactionSourceType?.Spent]: 'Spent',
  };
  return adjustmentTranslation[source];
};

/**
 * @description Dynamically display date range based on differents
 * @example
 * "From 13 July 2023"
 * "13 - 28 July 2023"
 * "13 Jan - 28 Feb 2023"
 * "31 Dec 2022 - 10 Jan 2023"
 */
export const formatDateRange = (startDate: Date, endDate?: Date): string => {
  const sameMonthFormat = 'DD';
  const differentMonthFormat = 'DD MMM';
  const defaultDateFormat = 'DD MMM YYYY';

  if (startDate && !endDate) {
    return `From ${dayjs(startDate).format(defaultDateFormat)}`;
  }

  const start = dayjs(startDate);
  const end = dayjs(endDate);

  let formatted = '';

  if (start.isSame(end, 'month') && start.isSame(end, 'year')) {
    // Same month and year
    formatted = `${start.format(sameMonthFormat)} - ${end.format(sameMonthFormat)} ${end.format(
      'MMM YYYY'
    )}`;
  } else if (start.isSame(end, 'year')) {
    // Different month, same year
    formatted = `${start.format(differentMonthFormat)} - ${end.format(
      differentMonthFormat
    )} ${end.format('YYYY')}`;
  } else {
    // Different year
    formatted = `${start.format(defaultDateFormat)} - ${end.format(defaultDateFormat)}`;
  }

  return formatted;
};

/**
 * @description
 * Join multiple string where falsy value is excluded
 * @example
 * compactJoin(["First Name", "", "Last Name", null], ',') -> "First Name, Last Name"
 */
export const compactJoin = (text: (string | null | undefined)[], separator = ', ') => {
  return join(compact(text), separator);
};

export const codeFormat = (code: string | null = '') => {
  if (!code) return '';

  return `#${code}`;
};

/**
 * @description
 * Remove string with html tags to plain text
 */
export const htmlStringToText = (description?: string): string => {
  if (!description) return ''

  // Decode HTML entities using the 'he' library
  const decodedText = he.decode(description)

  // Remove HTML tags using a regular expression
  return decodedText.replace(/<[^>]*>?/gm, '')
}

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) return `${sizeInBytes} B`;
  const sizeInKB = sizeInBytes / 1024;
  if (sizeInKB < 1024) return `${sizeInKB.toFixed(2)} KB`;
  const sizeInMB = sizeInKB / 1024;
  if (sizeInMB < 1024) return `${sizeInMB.toFixed(2)} MB`;
  const sizeInGB = sizeInMB / 1024;
  return `${sizeInGB.toFixed(2)} GB`;
};
