import { DayType } from '~/api';

export const CountryCode = '+60';
export const DefaultCurrency = 'MYR';

// ======================= COLOR
export const primaryGradient = '#C49A6C';
export const secondaryGradient = '#662D91';

// ======================= PATH
export const path = {
  notification: '/notification',
  maintenance: '/maintenance',
  membershipImport: 'membership-import'
};

// ======================= DAY-JS Format
/**
 * @example '05 October 2023'
 */
export const ValidUntilDateFormat = 'DD MMMM YYYY';
/**
 * @example 'Wed'
 */
export const DayFormat = 'ddd';
/**
 * @example '01 Jul'
 */
export const ShortDateFormat = 'DD MMM';
/**
 * @example 'Wed 01 Jul'
 */
export const DayDateFormat = 'ddd DD MMM';
/**
 * @example '01 Jul 2023'
 */
export const FullDateFormat = 'DD MMM YYYY';
/**
 * @example '01 Jul 2023, 10:30 AM'
 */
export const FullDateTimeFormat = 'DD MMM YYYY, h:mm A';
/**
 * @example '01 Jul 2023, 10:30 AM'
 */
export const FullDateTimeWithSecondsFormat24H = 'YYYY-MM-DD HH:mm:ss';
/**
 * @example '2023-02-23'
 */
export const DateFormatInput = 'YYYY-MM-DD';
/**
 * @example '10:30 AM'
 */
export const TimeFormat = 'h:mm a';
export const TimeFormat2Digit = 'hh:mm a';
export const TimeFormat24Hours = 'HH:mm:ss';

export const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// ======================= REGEX
/**
 * @description without "HTTP"
 */
export const REGEX_URL =
  '/^[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/';
export const REGEX_HTTP_URL =
  '/^https?://(?:www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/';

export const PRODUCT_DEEPLINK = `${import.meta.env.VITE_QR_DEEPLINK}home/productDetail/`;

// ======================= ENV
export const OrderSlotMaxDay = +import.meta.env.VITE_ORDER_SLOT_MAX_DAY || 7;
export const DineInPaymentMethodCode =
  import.meta.env.VITE_DINE_IN_PAYMENT_METHOD_CODE || 'dine-in-payment';
export const NetworkRequestFailed = 'Network request failed';

// ======================= SHOULD GET FROM BACKEND or through api
export const ModifierFacetCode = 'modifier';
export const CollectionFacetCode = 'assign-collection';
export const ManualFulfillmentCode = 'manual-fulfillment';
export const FulfillmentTrackingCodeLength = 24;

// TODO: to be remove & use get from backend
export const MagicalBeanChannelCode = 'magicalbeansmalaysia'