import React, { useMemo, useState } from 'react';
import { IconButton, ImageViewer, Popover } from '@scalingworks/react-admin-ui';
import { FiUploadCloud } from 'react-icons/fi';
import { useNavigation } from '@refinedev/core';
import { path } from '~/config/constant';

export const MembershipImportPopover: React.FC = () => {
  // ======================== HOOKS
  const nav = useNavigation();

  // ======================== STATES
  const [open, setOpen] = useState(false);

  // ======================== VIEWS
  const Trigger = useMemo(() => {
    return (
      <IconButton>
        <FiUploadCloud className='!text-gray-400' size={25} />
      </IconButton>
    );
  }, []);

  return (
    <div>
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>{Trigger}</Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="p-4" align="end" sideOffset={20} style={{ minWidth: 400 }}>
            <section className="flex flex-col justify-center items-start">
              <h3 className="cursor-default font-medium mb-4">Membership Data Import</h3>

              <div className="flex flex-col justify-center items-center gap-2">
                <ImageViewer
                  onClick={() => nav.push(path.membershipImport + '?type=feedme')}
                  src={'/images/pos/feedme.png'}
                  className="!w-14 !h-14 rounded-lg object-cover mr-2 cursor-pointer"
                  alt="feedme"
                />
              </div>
            </section>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};
