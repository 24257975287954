import { Button, Dialog, NumberField, SelectField, TextField } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import { PointTransactionAdminActionType as AdjustType, ManualAdjustPointInput } from '~/api';
import startCase from 'lodash/startCase';
import { join } from 'lodash';

type Adjustment = {
  type: AdjustType;
  points: number;
  remarks?: string;
};

export const AdjustLoyaltyPoints: React.FC<Props> = (props) => {
  const { open, setOpen, userId, onCompleted, pointLabel = 'Points' } = props;

  // ==================== HOOKS
  const apiUrl = useApiUrl();
  const t = useTranslate();

  // ==================== STATES
  const [adjustment, setAdjustment] = useState<Adjustment>({
    type: AdjustType.Grant,
    points: 0,
    remarks: undefined,
  });

  // ==================== API
  const { mutate: adjustPoint, isLoading: updating } = useCustomMutation();

  const onUpdate = () => {
    const { type: action, points: point, remarks } = adjustment;
    adjustPoint(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'manualAdjustPoints',
          variables: {
            input: {
              type: 'ManualAdjustPointInput!',
              value: {
                toUserIds: [userId],
                action,
                point,
                remarks,
              } as ManualAdjustPointInput,
            },
          },
        },
        successNotification: {
          message: t('customer.loyalty.updated'),
          type: 'success',
        },
        errorNotification: {
          message: t('customer.loyalty.failedToUpdate'),
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: () => {
          setOpen(false);
          onCompleted?.();
        },
      }
    );
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 lg:w-1/2">
          <Dialog.Title>{join([pointLabel, t('customer.loyalty.adjustment')], ' ')}</Dialog.Title>
          <div className="flex flex-col space-y-4 p-4 w-full">
            <SelectField
              label={t('customer.loyalty.adjust.actions', {}, 'Actions')}
              options={Object.keys(AdjustType).map((key) => ({
                label: startCase(key),
                value: AdjustType[key as keyof typeof AdjustType],
              }))}
              value={adjustment.type}
              onValue={(type) => setAdjustment((prev) => ({ ...prev, type }))}
              required
            />

            <NumberField
              label={t('customer.loyalty.adjust.points', {}, 'Points')}
              value={`${adjustment.points.toLocaleString()}`}
              onValue={(points) => setAdjustment((prev) => ({ ...prev, points: +points }))}
              required
              suffix={<span className="text-smoke-500">{pointLabel}</span>}
              prefix={
                <span className="text-smoke-500">
                  {adjustment.type === AdjustType.Grant ? '+' : '-'}
                </span>
              }
            />

            <TextField
              type="textarea"
              label={t('customer.loyalty.adjust.reason', {}, 'Reason')}
              value={adjustment.remarks}
              onValue={(remarks) => setAdjustment((prev) => ({ ...prev, remarks }))}
              placeholder={t('customer.loyalty.adjust.reasonPlaceholder')}
            />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button variant="default" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={!adjustment.points || !adjustment.type}
              loading={updating}
              variant="solid"
              onClick={onUpdate}
            >
              {t('common.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
