import React, { useEffect, useRef, useState, useTransition } from 'react';
import { Card, ImageViewer } from '@scalingworks/react-admin-ui';
import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import { MembershipTier, PointSetting, PointSettingType, getSdk } from '~/api';
import {
  ActionButton,
  ActionButtonRefProps,
  Divider,
  TriggerConfirmModal,
  flexRow,
  paddingCard,
} from '~/components';
import { numeralThousandFormat } from '~/config/helper';
import { resourceNames } from '~/resources/resource-names';
import { FiPlus } from 'react-icons/fi';
import { useNavigation, useTranslate } from '@refinedev/core';
import { GQLClient } from '~/config/gql-client';
import { PointSettingsForm } from '../pointSettingsForm';
import { PointSettingsView } from '../pointSettings';
import { Props } from './props';

export const membershipTierDashboardResource = createResourceListingPage({
  title: '',
  customHelmet: 'Membership Tier',
  resourceName: resourceNames.membershiptier,
  fields: ['id', 'level', 'imageUrl', 'minimumStars', 'name'],
  defaultPageSize: 25,
  defaultSorter: [{ field: 'id', order: 'desc' }],
  filterConfig: { alwaysExpanded: true },
  showTitle: false,
  allowCreate: true,
  allowSearch: true,
  searchConfig: {
    placeholder: ({ t }) =>
      t('membershipTiers.placeholder.search', { fallback: 'Search by Tier Name', ns: 'common' }),
  },
  columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
    return [
      {
        id: 'level',
        header: t('membershipTiers.column.level', {
          fallback: 'Level',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, level } = data.row.original as MembershipTier;
          return (
            <div>
              <span>{level?.toString()}</span>
            </div>
          );
        },
      },
      {
        id: 'tier',
        header: t('membershipTiers.column.tier', {
          fallback: 'Tier',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, name, imageUrl } = data.row.original as MembershipTier;
          return (
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ImageViewer
                  src={imageUrl || undefined}
                  className="!w-14 !h-14 rounded-lg object-cover mr-2"
                  alt="Tier Image"
                />
                <span>{name}</span>
              </div>
            </div>
          );
        },
      },
      {
        id: 'points',
        header: t('membershipTiers.column.points', {
          fallback: 'Points Required',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, minimumStars } = data.row.original as MembershipTier;
          return (
            <div>
              <span>{numeralThousandFormat(minimumStars)}</span>
            </div>
          );
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const { id } = data?.row?.original as MembershipTier;
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.edit', {
                    fallback: 'Edit',
                    ns: 'common',
                  }),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id }),
                },
                // {
                //   label: 'Delete',
                //   name: 'delete',
                //   onAction: () => invokeDelete({ id }),
                //   render: (onAction) => {
                //     return (
                //       <button type="button">
                //         <TriggerConfirmModal
                //           visible={showDeleteConfirmation}
                //           onOpenChange={(val) => {
                //             const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                //             setShowDeleteConfirmation(val);
                //             actionButtonSetOpen && actionButtonSetOpen(val);
                //           }}
                //           onPressConfirm={onAction}
                //         />
                //       </button>
                //     );
                //   },
                // },
              ]}
            />
          );
        },
      },
    ];
  },
});

export const mainWrapper = ({
  title,
  content,
  renderExtra,
}: {
  title: string;
  content: React.ReactElement;
  renderExtra?: () => React.ReactElement;
}) => {
  return (
    <Card>
      <div
        style={{
          ...flexRow,
          ...paddingCard,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="font-medium text-xl">{title}</p>
        {renderExtra?.()}
      </div>
      <Divider haveMargin={false} />
      <div>{content}</div>
    </Card>
  );
};

export const MembershipTierMain: React.FC<Props> = (props) => {
  const { create } = useNavigation();

  const [pointsData, setPointsData] = useState<PointSetting[]>();
  const [forceRender, setForceRender] = useState(false);
  const t = useTranslate();
  const gqlClient = GQLClient?.getInstance();
  useEffect(() => {
    getSdk(gqlClient)
      ?.getPointSettings()
      ?.then((res) => {
        setPointsData(res?.getPointSettings?.items);
      });
  }, [forceRender]);

  const renderButton = ({
    title,
    onPress,
    prefixIcon,
  }: {
    title: string;
    onPress: () => void;
    prefixIcon?: React.ReactElement;
  }) => {
    return (
      <button
        className="border-primary-500 text-primary-500 rounded py-2 px-4 gap-2"
        style={{ borderWidth: 1, alignItems: 'center', ...flexRow }}
        type="button"
        onClick={onPress}
      >
        {prefixIcon}
        {title}
      </button>
    );
  };
  return (
    <div className="max-w-screen-2xl mx-auto py-3 px-6">
      <p className="text-2xl mb-4">Loyalty</p>
      <div className="flex flex-col gap-3">
        {mainWrapper({
          title: t('membershipTiers.pointSettings.name'),
          content: <PointSettingsView pointsData={pointsData} />,
          renderExtra: () => {
            return <PointSettingsForm pointsData={pointsData} setForceRender={setForceRender} />;
          },
        })}
        {mainWrapper({
          title: t('membershipTiers.dashboard.name'),
          content: membershipTierDashboardResource(),
          // renderExtra: () =>
          //   renderButton({
          //      title: t('actions.addNew').toUpperCase(),
          //     onPress: () => {
          //       create?.(resourceNames?.membershiptier);
          //     },
          //     prefixIcon: <FiPlus />,
          //   }),
        })}
      </div>
    </div>
  );
};
