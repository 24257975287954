import React, { useState } from 'react';
import { FormBuilder, ModalWrapper } from '~/components';
import { Props } from './props';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { FormItemProps } from '~/components/FormBuilder/props';
import { GetTimeSlotInput, OutletFulfillmentType, TimeSlot } from '~/api';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { SelectField, Spinner } from '@scalingworks/react-admin-ui';
import { useUpdateOrderInfo } from '../show/hooks';

export const UpdateOrderOutletModal: React.FC<Props> = (props) => {
  const { open, setOpen, orderId, initialValue, fulfillmentMethod, onSuccess } = props;
  const { purchaseOutletId, outletFulfillmentId = '' } = initialValue || {};

  // ====================== HOOKS
  const { updateOrderOutletFulfillment, isLoading: updatingOrder } = useUpdateOrderInfo();
  const apiUrl = useApiUrl();
  const t = useTranslate();

  const { data: slots, isLoading } = useCustom<TimeSlot[]>({
    method: 'get',
    url: apiUrl,
    metaData: {
      fields: ['date', { slots: ['outletFulfillmentId', 'slot'] }] as ResourceField<TimeSlot>[],
      operation: 'getFulfillmentTimeSlotsByType',
      variables: {
        input: {
          value: {
            type: `${fulfillmentMethod}` as OutletFulfillmentType,
            // TODO: handle on-the-spot changing outlet
            outletId: purchaseOutletId || '',
          } as GetTimeSlotInput,
          type: 'GetTimeSlotInput!',
        },
      },
    },
  });

  // ====================== STATES
  const [selectedOutletFulfillmentId, setSelectedOutletFulfillmentId] =
    useState(outletFulfillmentId);

  // ====================== VARIABLES
  // TODO: think how to design the outlet fulfillment select, because it might have
  // DAY | DATE type, while cannot just do a simple date picker because it needs the outletFulfillment ID

  // ====================== EVENTS
  const onConfirm = () => {
    updateOrderOutletFulfillment(
      orderId,
      {
        outletFulfillmentId: selectedOutletFulfillmentId,
        // purchaseOutletId
      },
      {
        t,
        mutateOptions: {
          onSuccess: () => {
            onSuccess?.();
            setOpen(false);
          },
        },
      }
    );
  };

  // ====================== VIEWS
  const DateSlotSelection: React.FC = () => {
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="">
        <SelectField
          options={(slots?.data || []).flatMap((item) => {
            const { date, slots } = item;
            return slots.map((slot) => ({
              label: `${date} ${slot.slot}`,
              value: slot.outletFulfillmentId,
            }));
          })}
          value={selectedOutletFulfillmentId}
          onValue={(selected) => {
            setSelectedOutletFulfillmentId(selected);
          }}
        />
      </div>
    );
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      title={t('actions.updateStoreOrSlot')}
    >
      <section className="p-4">
        <DateSlotSelection />
      </section>
    </ModalWrapper>
  );
};
