import { useNavigation, useTranslate } from '@refinedev/core';
import { PopoverNotificationCenter, NotificationBell, IMessage } from '@novu/notification-center';
import React from 'react';
import { path } from '../constant';
import { Button } from '@scalingworks/react-admin-ui';
import { ConnectPrinter } from './ConnectPrinter';
import { MembershipImportPopover } from './MembershipImportPopover';

export const QuickActions: React.FC = () => {
  const nav = useNavigation();
  const t = useTranslate();

  // ======================= VIEWS
  return (
    <div className="flex flex-row items-center space-x-2">
      {/* TODO: have a flag to indicate if to show what features */}
      <MembershipImportPopover />
      <ConnectPrinter />
      <PopoverNotificationCenter
        colorScheme="light"
        onNotificationClick={(notification) => {
          const url = notification?.cta?.data?.url;
          if (url) {
            window.location.href = url;
          }
        }}
        onActionClick={(tempId, type, notif) => {
          const url = notif?.cta?.data?.url;
          if (url) {
            window.location.href = url;
          }
        }}
        footer={() => (
          <div className="mx-2 p-1 flex flex-row items-center justify-center">
            <Button
              className="w-full"
              size="sm"
              variant="solid"
              onClick={() => nav.push(path.notification)}
            >
              {t('notificationBlasts.notification.all')}
            </Button>
          </div>
        )}
      >
        {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
      </PopoverNotificationCenter>
    </div>
  );
};
