import React, { useContext, useEffect, useState } from 'react';
import { ActionButton, TableBuilder, ViewBuilder, flexRow } from '~/components';
import { resourceNames } from '~/resources/resource-names';
import { ImageViewer, Tag } from '@scalingworks/react-admin-ui';
import { Product, ProductList } from '~/api';
import { numeralThousandFormat } from '~/config/helper';
import isEmpty from 'lodash/isEmpty';
import minBy from 'lodash/minBy';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { formatBackendPrice, renderStatusTag } from '~/resources/helpers';
import { productFields } from '../create/type';
import { Props } from './props';
import { ChannelContext } from '~/providers/Channel/context';

export const CollectionShow: React.FC<Props> = (props) => {
  const { collectionData, helpers, editNavigation: edit } = props;

  // ====================== HOOKS
  const { currencyForColHeader } = useContext(ChannelContext);
  const productIds = collectionData?.products?.items?.map((subItem) => subItem?.id) ?? [];
  const apiUrl = useApiUrl();
  const [searchText, setSearchText] = useState<string>();
  const t = useTranslate();

  // ====================== API
  const { data: selectedProducts, isLoading: loadingOrderItem } = useCustom<ProductList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      operation: 'getProducts',
      fields: productFields,
      variables: {
        options: {
          value: {
            filter: {
              id: {
                in: productIds,
              },
            },
          },
          type: 'ProductListOptions',
        },
        search: searchText,
      },
    },
    queryOptions: {
      enabled: !!productIds && !isEmpty(productIds),
    },
  });

  // ====================== VIEWS
  return (
    <ViewBuilder
      {...helpers}
      hideBackButton
      title={collectionData?.name}
      items={[
        {
          cardTitle: t('common.general'),
          cardHeaderExtra: () => {
            return (
              <div>
                <ActionButton
                  customTitle={t('common.action').toUpperCase()}
                  actions={[
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: () => {
                        edit(resourceNames?.collection, collectionData?.id);
                      },
                    },
                  ]}
                />
              </div>
            );
          },
          config: [
            {
              title: t('collection.form.name'),
              data: [collectionData?.name],
              type: 'text',
            },
            // NOTE: Not applicable for now
            // {
            //   title: t('collection.form.images'),
            //   data: [...(collectionData?.assets?.map((subItem) => subItem?.source) || [])],
            //   type: 'image',
            // },
            {
              title: t('collection.form.status'),
              data: [collectionData?.isPrivate],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>{renderStatusTag(!collectionData?.isPrivate)}</div>
                  </div>
                );
              },
            },
          ],
        },
      ]}
      extra={[
        {
          cardTitle: 'Products',
          render: () => {
            return (
              <div>
                <TableBuilder
                  searchInputProps={{
                    onChange: (text) => {
                      setSearchText(text);
                    },
                  }}
                  columns={[
                    {
                      id: 'product',
                      header: t('collection.form.product.name'),
                      cell: (data: Product) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <ImageViewer
                              src={data?.assets?.[0]?.source}
                              className="!w-14 !h-14 rounded-lg object-cover mr-2"
                              alt="Product"
                            />
                            <span>{data?.name}</span>
                          </div>
                        );
                      },
                    },
                    {
                      id: 'inventory',
                      header: t('collection.form.product.inventory'),
                      cell: (data: Product) => {
                        let totalStock = 0;
                        data?.variants?.forEach((sub) => {
                          totalStock += sub?.stockOnHand;
                        });
                        return <p>{numeralThousandFormat(totalStock)}</p>;
                      },
                    },
                    {
                      id: 'price',
                      header: t('collection.form.product.price', {
                        currency: currencyForColHeader(),
                      }),
                      cell: (data: Product) => {
                        let lowestValue = minBy(data?.variants, 'price');

                        return (
                          <p>{formatBackendPrice({ price: lowestValue?.price, isDivide: true })}</p>
                        );
                      },
                    },
                  ]}
                  data={selectedProducts?.data?.items}
                  loading={!isEmpty(productIds) && loadingOrderItem}
                />
              </div>
            );
          },
        },
      ]}
    />
  );
};
