import React, { useEffect } from 'react';
import { FormBuilder } from '~/components';
import { useForm } from '@refinedev/react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';

export const MembershipTierCreate: React.FC<Props> = (props) => {
  const { onSubmit, membershipTierData, isUpdate } = props;

  const form = useForm();
  const t = useTranslate();

  useEffect(() => {
    if (!isEmpty(membershipTierData)) {
      form?.setValue('image', [membershipTierData?.imageUrl]);
    }
  }, [membershipTierData]);

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.membershiptier}
        customForm={form}
        title={isUpdate ? t('membershipTiers.update.name') : t('membershipTiers.update.name')}
        isUpdate={isUpdate}
        onSubmit={(data) => onSubmit?.(data)}
        items={() => {
          return [
            {
              name: 'name',
              title: t('membershipTiers.form.name'),
              defaultValue: membershipTierData?.name,
              required: true,
              placeholder: t('membershipTiers.placeholder.name'),
            },
            {
              name: 'level',
              title: t('membershipTiers.form.level'),
              defaultValue: membershipTierData?.level,
              type: 'number',
              required: true,
              placeholder: t('membershipTiers.placeholder.level'),
            },
            {
              name: 'pointsRequired',
              title: t('membershipTiers.form.points'),
              defaultValue: membershipTierData?.minimumStars,
              type: 'number',
              required: true,
              placeholder: t('membershipTiers.placeholder.points'),
            },
            {
              name: 'image',
              title: t('membershipTiers.form.image'),
              type: 'upload',
              fileType: 'image',
              placeholder: t('product.placeholder.images'),
              hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
              defaultValue: !!membershipTierData?.imageUrl
                ? [membershipTierData?.imageUrl]
                : undefined,
            },
          ];
        }}
      />
    </div>
  );
};
