import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { resourceNames } from '../resource-names';
import {
  Channel,
  MetabaseDashboard,
  MetabaseIframeUrlOptions,
  MetabaseQuestion,
  ShippingMethod,
} from '~/api';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { IframeRefreshButton, Loading, ShowPageWrapper } from '~/components';
import { MdOutlineManageAccounts, MdRefresh } from 'react-icons/md';
import { AiOutlineBarChart } from 'react-icons/ai';
import { IconButton } from '@scalingworks/react-admin-ui';
import { useContext, useEffect, useRef } from 'react';
import { ChannelContext } from '~/providers/Channel/context';
import { useMetabaseApi } from '~/config/metabase-hook';
import { ResourceContext } from '../type';

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.profile,
});

const Dashboard: React.FC = () => {
  const { channel } = useContext(ChannelContext);

  // ======================= HOOKS
  const t = useTranslate();
  const { useGetMetabaseUrl } = useMetabaseApi();

  // ======================= API
  const {
    data: overviewUrl,
    isLoading: loadingOverview,
    refetch: refetchOverview,
  } = useGetMetabaseUrl({
    dashboard: MetabaseDashboard.Overview,
  });

  // ======================= EVENTS
  const refetchAll = () => {
    refetchOverview();
  };

  // ======================= VIEWS
  return (
    <section className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.report}
        title={t('dashboard.name', {}, 'Dashboard')}
        showBackButton={false}
        extra={<IframeRefreshButton onClick={refetchAll} />}
      >
        <section className="flex flex-col space-y-8">
          <div className="flex flex-col space-y-4">
            <h3 className="text-xl">{t('dashboard.section.overview', {}, 'Overview')}</h3>
            {loadingOverview ? (
              <Loading />
            ) : (
              <div style={{ position: 'relative', width: '100%', height: 600 }}>
                <iframe
                  className="w-full"
                  height={600}
                  src={overviewUrl?.data as string}
                />
                {/* Temporary stick a section to cover the keyword */}
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '60px',
                    backgroundColor: 'white',
                    zIndex: 1,
                  }}
                />
              </div>
            )}
          </div>
        </section>
      </ShowPageWrapper>
    </section>
  );
};

export const dashboardResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames.dashboard,
    label: 'Dashboard',
    icon: <AiOutlineBarChart />,
    defaultValues: {},
    fields: ['id'],
    columns: () => [],
    allowCreate: false,
    allowDelete: false,
    allowEdit: false,
    allowSearch: false,
    list: {
      render: () => {
        return <Dashboard />;
      },
    },
    show: defineShowPage({
      component: () => {
        return <Dashboard />;
      },
    }),
  });
};
