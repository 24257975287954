import { Button, DayField, Dialog } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';
import dayjs from 'dayjs';
import { UpdateMembershipInput } from '~/api';
import { DateFormatInput } from '~/config/constant';

export const UpdateLoyaltyValidity: React.FC<Props> = (props) => {
  const { open, setOpen, userId, originalDate, onCompleted } = props;

  // ==================== HOOKS
  const apiUrl = useApiUrl();
  const t = useTranslate();

  // ==================== STATES
  const [date, setDate] = useState<Date | undefined>(originalDate || dayjs().endOf('day').toDate());

  // ==================== API
  const { mutate: updateDate, isLoading: updating } = useCustomMutation();

  const onUpdate = async () => {
    updateDate(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'updateMembership',
          variables: {
            input: {
              type: 'UpdateMembershipInput!',
              value: {
                userId,
                pointValidUntil: dayjs(date).format(DateFormatInput),
              } as UpdateMembershipInput,
            },
          },
        },
        successNotification: {
          message: t(
            'customer.loyalty.validityUpdateSuccess',
            {},
            'Membership validity date updated'
          ),
          type: 'success',
        },
        errorNotification: {
          message: t(
            'customer.loyalty.validityUpdateFailed',
            {},
            'Failed to update validity date'
          ),
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: () => {
          setOpen(false);
          onCompleted?.();
        },
      }
    );
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 lg:w-1/2">
          <Dialog.Title>
            {t('customer.loyalty.updateMembershipValidity', {}, 'Update Membership Validity')}
          </Dialog.Title>
          <div className="p-4">
            <DayField label="Date" min={new Date()} value={date} onValue={setDate} />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button variant="default" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button disabled={!date} loading={updating} variant="solid" onClick={onUpdate}>
              {t('common.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
