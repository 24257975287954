import { getSdk } from '~/api';
import { FetcherOptions } from '.';
import { generateSort } from '../helper';

export const redemptionHistoriesCustomGetList = (options: FetcherOptions) => {
  const { client, filters, metaData, pagination, sort } = options;
  const current = pagination?.current || 1;
  const pageSize = pagination?.pageSize || 25;

  const tabListFilter = filters?.find((subItem: any) => subItem?.key === 'listingTab');
  const searchFilter = filters?.find((subItem: any) => subItem?.field === 'search');
  const createdAtBegin = filters?.find(
    (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'gte'
  );
  const createdAtEnd = filters?.find(
    (subItem: any) => subItem?.field === 'createdAt' && subItem?.operator === 'lte'
  );
  const usedAtBegin = filters?.find(
    (subItem: any) => subItem?.field === 'usedDate' && subItem?.operator === 'gte'
  );
  const usedAtEnd = filters?.find(
    (subItem: any) => subItem?.field === 'usedDate' && subItem?.operator === 'lte'
  );
  const type = filters?.find(
    (subItem: any) => subItem?.field === 'type' && subItem?.operator === 'eq'
  );

  const tabFilter =
    (tabListFilter?.value?.[0]?.value === 'ALL' ? undefined : tabListFilter?.value?.[0]?.value) ||
    undefined;

  return getSdk(client)
    ?.GetRedemptionHistories({
      input: {
        type: tabFilter || type?.value,
        search: searchFilter?.value,
        beginDateRangeCreatedAt: createdAtBegin?.value,
        endDateRangeCreatedAt: createdAtEnd?.value,
        beginDateRangeUsedDate: usedAtBegin?.value,
        endDateRangeUsedDate: usedAtEnd?.value,
        membershipUserId: metaData?.userId,
      },
      options: {
        skip: (current - 1) * pageSize,
        take: pageSize,
        sort: generateSort(sort),
      },
    })
    ?.then((res) => ({
      data: res?.getRedemptionHistories?.items,
      total: res?.getRedemptionHistories?.totalItems,
    }));
};
