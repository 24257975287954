import { GraphQLClient } from 'graphql-request';
import { type PatchedRequestInit } from 'graphql-request/dist/types';
import { SalesChannel } from '~/api';
import { authStorageKey, currentChannelToken } from './auth-provider';

export const GQLClientHeader = {
  authorization: 'authorization',
  vendureToken: 'vendure-token',
  salesChannel: 'x-sales-channel',
};

export class GQLClient extends GraphQLClient {
  private static instance: GQLClient | null = null;
  public static apiUrl = '';

  private constructor(url: string, options?: PatchedRequestInit) {
    super(url, options);
    GQLClient.apiUrl = url;
  }

  public static getInstance(): GQLClient {
    const url = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000/admin-api';

    if (GQLClient.instance === null) {
      GQLClient.instance = new GQLClient(url);

      // Default headers
      this.instance?.setHeader('x-sales-channel', SalesChannel.Backoffice);
    }

    return GQLClient.instance;
  }

  public static prepareApiHeaders = () => {
    const authToken = localStorage.getItem(authStorageKey) || '';
    const channelToken = localStorage.getItem(currentChannelToken) || '';

    return {
      [GQLClientHeader.salesChannel]: SalesChannel.Backoffice,
      [GQLClientHeader.authorization]: `Bearer ${authToken}`,
      [GQLClientHeader.vendureToken]: channelToken,
    };
  };
}
