import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { FiSettings } from 'react-icons/fi';

import { resourceNames } from '../resource-names';
import { SettingListPage } from './list';
import { ShippingMethodCreatePage } from './create';
import { ShippingMethodShowPage } from './show';
import { Channel, ShippingMethod } from '~/api';
import { ResourceContext } from '../type';

/**
 * NOTE:
 * the entire setting resource is using a shipping method
 * with custom rendered listing / create / edit page
 * there are other misc setting in listing as well
 */

export const Post_Code_Arg = 'postCode';

export const shippingMethodFields: ResourceField<ShippingMethod>[] = [
  'id',
  'code',
  'createdAt',
  'name',
  { checker: ['code', { args: ['name', 'value'] }] },
  { calculator: ['code', { args: ['name', 'value'] }] },
  { customFields: [{ tierRange: ['id', 'minQuantity', 'maxQuantity', 'value'] }] },
];

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.shippingMethod,
});

export const settingResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames.shippingMethod,
    label: 'Settings',
    icon: <FiSettings />,
    defaultValues: {},
    fields: shippingMethodFields,
    defaultPageSize: 25,
    filterConfig: {
      alwaysExpanded: true,
    },
    columns: ({ t }) => [
      {
        id: 'id',
        header: t('settings.column.orderId', { fallback: 'Order ID', ns: 'common' }),
        cell: (data) => {
          return '';
        },
      },
    ],
    list: {
      render: () => {
        return <SettingListPage />;
      },
    },
    create: {
      render: (helpers) => {
        return <ShippingMethodCreatePage />;
      },
    },
    show: defineShowPage({
      component: (props) => {
        return <ShippingMethodShowPage queryResult={props.queryResult} />;
      },
    }),
  });
};
