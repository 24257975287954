import { Customer } from '~/api';
import { FormItemProps } from '~/components/FormBuilder/props';
import { CountryCode } from '../constant';
import { useTranslate } from '@refinedev/core';

export const customerFormBuilder = (customer?: Customer): FormItemProps[] => {
  const { id, firstName, lastName, emailAddress, phoneNumber, customFields } = customer || {};
  const { status, gender, dateOfBirth } = customFields || {};

  const t = useTranslate();

  return [
    {
      title: t('customer.form.firstName'),
      name: 'firstName',
      defaultValue: firstName,
      placeholder: t('customer.placeholder.firstName'),
      required: true,
    },
    {
      title: t('customer.form.lastName'),
      name: 'lastName',
      defaultValue: lastName,
      placeholder: t('customer.placeholder.lastName'),
      required: true,
    },
    {
      title: t('customer.form.email'),
      name: 'emailAddress',
      defaultValue: emailAddress,
      placeholder: t('customer.placeholder.email'),
      required: true,
      props: {
        disabled: !!emailAddress,
      },
    },
    {
      title: t('customer.form.contact'),
      name: 'phoneNumber',
      defaultValue: phoneNumber,
      type: 'phone',
      placeholder: t('customer.placeholder.contact'),
      required: true,
      props: {
        disabled: !!phoneNumber,
      },
    },
    {
      // temporary text input
      title: t('customer.form.dateOfBirth'),
      name: 'birthDate',
      placeholder: t('customer.placeholder.dateOfBirth'),
      type: 'date-text-input',
      defaultValue: dateOfBirth,
    },
    {
      title: t('customer.form.gender'),
      name: 'gender',
      type: 'radio',
      options: ['MALE', 'FEMALE'].map((value) => ({
        label: t(`customer.gender.${value.toLowerCase()}`),
        value,
      })),
      defaultValue: gender,
    },
    {
      title: t('customer.form.status'),
      name: 'status',
      type: 'radio',
      options: ['ACTIVE', 'INACTIVE'].map((value) => ({
        label: t(`customer.status.selection.${value.toLowerCase()}`),
        value,
      })),
      defaultValue: status || 'ACTIVE',
      required: true,
    },
  ];
};
