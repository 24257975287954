import React, { useState } from 'react';
import { ModalWrapper } from '~/components';
import { Props } from './props';
import { useTranslate } from '@refinedev/core';
import { TextField } from '@scalingworks/react-admin-ui';
import { useUpdateOrderInfo } from '../show/hooks';

export const UpdateOrderRemarkModal: React.FC<Props> = (props) => {
  const { open, setOpen, orderId, initialRemark = '', onSuccess } = props;

  // ====================== HOOKS
  const { updateOrderRemark, isLoading: updatingOrder } = useUpdateOrderInfo();
  const t = useTranslate();

  // ====================== STATES
  const [latestRemark, setLatestRemark] = useState<string>(initialRemark);

  // ====================== VARIABLES

  // ====================== EVENTS
  const onConfirm = () => {
    updateOrderRemark(orderId, latestRemark, {
      t,
      mutateOptions: {
        onSuccess: () => {
          onSuccess?.();
          setOpen(false);
        },
      },
    });
  };

  // ====================== VIEWS

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={updatingOrder}
      title={t('actions.updateRemark')}
    >
      <section className="p-4">
        <TextField
          type='textarea'
          label={t('order.form.remark')}
          value={latestRemark}
          onValue={setLatestRemark}
          placeholder={t('order.placeholder.writeYourRemarkHere')}
        />
      </section>
    </ModalWrapper>
  );
};
