import { assertExists, Tag } from '@scalingworks/react-admin-ui';
import {
  createHelpers,
  createResource,
  defineCustomPage,
  ResourceField,
} from '@scalingworks/refine-react-admin';
import { FiCalendar } from 'react-icons/fi';

import { resourceNames } from '../resource-names';
import { Channel, getSdk, ReservationStatusType as StatusType, type Reservation } from '~/api';
import {
  ActionButton,
  FormBuilder,
  Loading,
  ReservationStatusUpdate,
  SomethingWentWrong,
} from '~/components';
import { format } from 'date-fns';
import { useState } from 'react';
import { ReservationShowPage } from './show';
import {
  INotificationContext,
  useCreate,
  useNavigation,
  useNotification,
  useOne,
  useTranslate,
  useUpdate,
} from '@refinedev/core';
import { reservationFormBuilder } from '~/config/Reservation/form';
import { useParams } from 'react-router-dom';
import split from 'lodash/split';
import { dateFormatter } from '../helpers';
import { FullDateTimeFormat } from '~/config/constant';
import { parsePhoneNumber } from 'libphonenumber-js';
import dayjs from 'dayjs';
import { ResourceContext } from '../type';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Reservation>({
    resourceName: resourceNames.reservation,
  });

export const reservationStatusColor: Record<StatusType, string> = {
  // refactor into tag color property because one of the property get overriden text-error-300)
  ACCEPTED: 'success',
  CANCELLED: 'error',
  MODIFIED: 'warning',
  PENDING: 'warning',
  // ACCEPTED: 'text-success-500 bg-success-100 border-success-500',
  // CANCELLED: 'text-error-300 bg-error-100 border-error-300',
  // // modified is same as pending (show pending instead)
  // MODIFIED: 'text-warning-500 bg-warning-100 border-warning-500',
  // PENDING: 'text-warning-500 bg-warning-100 border-warning-500',
};

const createDiningDate = (date: any, time: any) => {
  const withSetHour = new Date(new Date(date).setHours(new Date(time).getHours(), 0, 0, 0));
  const withSetMin = withSetHour?.setMinutes(new Date(time).getMinutes());
  const isoString = dayjs(withSetMin).toISOString();
  return isoString;
};

const formatData = (data: any) => {
  /**
   * any type because it's no longer compatible
   * between resourceType & form fields
   */
  const { date, time, adultPax, kidPax, occasion, ...restData } = data;
  const diningDate = createDiningDate(date, time);

  const payload = {
    ...restData,
    diningDate,
    occasion: Array.isArray(occasion) ? occasion : split(occasion, ','),
    adultPax: Number(adultPax),
    kidPax: Number(kidPax),
  };

  return payload;
};

export const reservationFields: ResourceField<Reservation>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'diningDate',
  'phoneNumber',
  'guestName',
  'adultPax',
  'kidPax',
  'occasion',
  'status',
  'remark',
  'cancellationRemark',
  { customer: ['id', 'firstName', 'lastName'] },
];

export const reservationResource = (context: ResourceContext) => {
  return createResource({
    defaultValues: {},
    name: resourceNames.reservation,
    label: 'List',
    fields: defineFields(reservationFields),
    filterControls: {
      diningDate: {
        type: 'daterange',
        config: {
          label: 'Dining Date',
        },
      },
    },
    defaultPageSize: 25,
    defaultSorter: [{ field: 'diningDate', order: 'desc' }],
    allowCreate: true,
    allowSearch: true,
    createConfig: {
      title: ({ t }) =>
        t('reservations.create.name', { fallback: 'Create Reservation', ns: 'common' }),
    },
    searchConfig: {
      placeholder: ({ t }) =>
        t('reservations.placeholder.search', {
          fallback: 'Search by Guest Name or Contact No.',
          ns: 'common',
        }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    allowDelete: false,
    columns: ({ LinkToDetails, navigateToEdit, refetchData, t }) => [
      {
        id: 'guestName',
        header: t('reservations.column.guest', {
          fallback: 'Guest Name',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, guestName = '', phoneNumber } = data.row.original;

          return (
            <>
              <LinkToDetails resourceId={id}>
                {guestName.trim()}
                <span> ({`${phoneNumber}`})</span>
              </LinkToDetails>{' '}
            </>
          );
        },
      },
      {
        id: 'pax',
        header: t('reservations.column.pax', {
          fallback: 'Pax',
          ns: 'common',
        }),
        cell: (data) => {
          const { id, adultPax = 0, kidPax = 0 } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <span>{adultPax + kidPax}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'status',
        header: t('reservations.column.status', {
          fallback: 'Status',
          ns: 'common',
        }),
        cell: (data) => {
          const { status, id } = data.row.original;
          let displayStatus = status;
          if (status === StatusType.Modified) {
            displayStatus = StatusType.Pending;
          }

          return (
            <LinkToDetails resourceId={id}>
              <Tag className={`rounded-lg`} color={reservationStatusColor[status] as any}>
                {t(`reservations.status.${displayStatus.toLowerCase()}`, {
                  fallback: displayStatus,
                  ns: 'common',
                }).toUpperCase()}
              </Tag>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'diningDate',
        header: t('reservations.column.date', {
          fallback: 'Dining Date',
          ns: 'common',
        }),
        cell: (data) => {
          const { diningDate, id } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <span>{dateFormatter(diningDate, FullDateTimeFormat)}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const reservationId = data.cell.getValue<string>();
          const [open, setOpen] = useState(false);
          const status = data.row.original.status;
          const t = useTranslate();

          const actions = [];

          if (status !== StatusType.Cancelled && status !== StatusType.Accepted) {
            actions.push({
              label: t('actions.edit'),
              name: 'edit',
              onAction: () => navigateToEdit({ id: reservationId }),
            });
          }

          if (status !== StatusType.Cancelled) {
            actions.push({
              label:
                status === StatusType.Accepted ? t('actions.cancel') : t('actions.updateStatus'),
              name: 'update-status',
              buttonClassName: status === StatusType.Accepted ? 'text-error-300' : '',
              onAction: () => setOpen(true),
            } as any);
          }

          return (
            <>
              <ReservationStatusUpdate
                open={open}
                setOpen={setOpen}
                reservationId={reservationId}
                initialStatus={status === StatusType.Modified ? StatusType.Pending : status}
                onCompleted={() => refetchData()}
              />
              <ActionButton actions={actions} />
            </>
          );
        },
      },
    ],
    formatBeforeSubmit: (data) => {
      return data;
    },
    create: {
      render: (helpers) => {
        const navigateTo = useNavigation();
        const notif = useNotification();
        const t = useTranslate();
        const { mutate: createReservation } = useCreate();

        return (
          <FormBuilder
            resourceName={resourceNames.reservation}
            title={t('reservations.create.add')}
            onSubmit={async (data) => {
              const { phoneNumber, date, time } = data;
              const parsed = parsePhoneNumber(phoneNumber);
              if (!parsed.isValid()) {
                notif?.open?.({
                  message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                  type: 'error',
                });
                return;
              }

              if (dayjs(createDiningDate(date, time)).isBefore(dayjs())) {
                notif?.open?.({
                  message: t(
                    'messages.datePassed',
                    'Selected date has passed. Please select a new date.'
                  ),
                  type: 'error',
                });
                return;
              }

              const payload = formatData(data);

              createReservation(
                {
                  resource: resourceNames.reservation,
                  values: payload,
                },
                {
                  onSuccess: () => navigateTo.list(resourceNames.reservation),
                }
              );
            }}
            items={() => reservationFormBuilder()}
          />
        );
      },
    },
    edit: {
      render(helpers) {
        const { id } = useParams();
        const navigateTo = useNavigation();
        const notif = useNotification();
        const t = useTranslate();

        if (!id) return <SomethingWentWrong />;

        const { data, isLoading } = useOne({
          resource: resourceNames.reservation,
          id,
          metaData: {
            fields: reservationFields,
          },
        });
        const mainData = data?.data as Reservation;

        const { mutate } = useUpdate();
        if (isLoading) return <Loading />;
        return (
          <FormBuilder
            resourceName={resourceNames.reservation}
            isUpdate
            title={t('reservations.edit.name', {}, 'Update Reservation')}
            onSubmit={async (data) => {
              const { phoneNumber, date, time } = data;
              const parsed = parsePhoneNumber(phoneNumber);
              if (!parsed.isValid()) {
                notif?.open?.({
                  message: t('messages.invalidPhoneFormat', {}, 'Invalid Phone Format'),
                  type: 'error',
                });
                return;
              }

              if (dayjs(createDiningDate(date, time)).isBefore(dayjs())) {
                notif?.open?.({
                  message: t('messages.datePassed'),
                  type: 'error',
                });
                return;
              }

              const payload = formatData(data);
              const { customerId, ...rest } = payload;
              mutate(
                {
                  id: '',
                  resource: resourceNames.reservation,
                  values: {
                    reservationId: id,
                    ...rest,
                  },
                },
                { onSuccess: () => navigateTo.list(resourceNames.reservation) }
              );
            }}
            items={() => reservationFormBuilder(mainData)}
          />
        );
      },
    },
    show: defineShowPage({
      component: (props) => {
        return <ReservationShowPage queryResult={props.queryResult} />;
      },
    }),
  });
};
