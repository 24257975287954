import { useNotification } from '@refinedev/core';
import { useCallback, useContext, useRef, useState } from 'react';
import { authStorageKey } from '~/config/auth-provider';
import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';
import { GQLClient } from '~/config/gql-client';
import { getSdk } from '~/api';
import * as pdfjsLib from 'pdfjs-dist';
import workerSrc from 'pdfjs-dist/build/pdf.worker?worker&url';
import { PrinterContext } from '~/providers/Printer/context';
import * as zip from '@zip.js/zip.js';

// https://github.com/mozilla/pdf.js/issues/10478#issuecomment-2242664642
// https://vitejs.dev/guide/assets.html#importing-script-as-a-worker
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;

/**
 *
 * @param headers other headers besides authorization (auto included)
 */
export const useDownload = (extraHeaders = {}) => {
  const notif = useNotification();
  const headers = {
    ...extraHeaders,
    authorization: `Bearer ${localStorage.getItem(authStorageKey)}`,
  };
  const gqlClient = GQLClient.getInstance();
  const backendUrl = import.meta.env.VITE_BACKEND_BASE_URL;
  const { printerRef } = useContext(PrinterContext);
  const downloadLoadingRef = useRef({
    bulkDownload: false,
    bulkPrint: false,
  });

  const downloadInvoice = useCallback(
    async (id: string, type?: string, url?: string) => {
      try {
        url = import.meta.env.VITE_ORDER_INVOICE_URL;
        if (!url) throw new Error('Invalid Url');

        let target = `${url}/${id}`;
        if (type) target += `/${type}`;

        const response = await fetch(target, { headers });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `order-invoice-${id}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        notif?.open?.({ message: 'Failed to download invoice', type: 'error' });
        console.error('Fetch failed: ', e);
      }
    },
    [headers]
  );

  const downloadReceipt = useCallback(
    async (transactionId: string, print?: boolean) => {
      try {
        // Get single-use link for receipt
        const singleUseLink = await getSdk(gqlClient).preparePaymentReceipt({ transactionId });

        // Validate receipt URL
        if (!singleUseLink.preparePaymentReceipt) throw new Error('Invalid Receipt URL');

        const target = `${backendUrl}/${singleUseLink.preparePaymentReceipt}`;
        const response = await fetch(target, { headers });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const blob = await response.blob();

        // Print
        if (print) {
          const arrayBuffer = await blob.arrayBuffer();
          const pdfData = new Uint8Array(arrayBuffer);

          // Load PDF with pdfjs-dist
          const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
          const page = await pdf.getPage(1); // Assuming first page for simplicity

          // Lower the scale, adjust resolution (you can tweak this for better quality)
          const viewport = page.getViewport({ scale: 3 });
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;
          ctx.scale(2.5, 2.5);
          // const { width, height } = adjustDimensions(viewport.width, viewport.height);
          const width = Math.ceil(viewport.width / 8) * 8;
          const height = Math.ceil(viewport.height / 8) * 8;

          // Set canvas dimensions
          canvas.width = width;
          canvas.height = height;

          // Render page to temporary canvas
          const tempCanvas = document.createElement('canvas');
          tempCanvas.width = viewport.width;
          tempCanvas.height = viewport.height;
          const tempCtx = tempCanvas.getContext('2d');
          await page.render({ canvasContext: tempCtx!, viewport }).promise;

          // Draw on main canvas
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, width, height);
          ctx.drawImage(tempCanvas, 0, 0);

          // Convert canvas to base64 image
          const base64Image = canvas.toDataURL('image/png', 1.0);
          console.log('Base64 Image:', base64Image); // Verify the base64 image

          // Convert base64 to an Image object
          const img = new Image();
          img.onload = async () => {
            // Now the image is loaded, pass it to the printer
            const encoder = new ReceiptPrinterEncoder();
            const finalWidth = adjustToMultipleOfEight(width * 0.8); // Adjust width for printer
            const finalHeight = adjustToMultipleOfEight(height * 1); // Adjust height for printer

            const result = encoder
              .image(img, finalWidth, finalHeight, 'atkinson', 255)
              .newline()
              .newline()
              .newline()
              .cut('full')
              .encode();

            if (!printerRef?.current) {
              notif?.open?.({ message: 'Printer not found', type: 'error' });
              return;
            }

            // Send result to printer
            await printerRef.current.print(result);
          };

          // Set the source of the image to the base64 string
          img.src = base64Image;
        } else {
          // Save Receipt
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', `order-receipt-${transactionId}.pdf`);
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        notif?.open?.({ message: 'Failed to download or print receipt', type: 'error' });
        console.error('Error:', e);
      }
    },
    [headers]
  );

  const bulkPrintReceipts = async (transactionIds: string[]) => {
    downloadLoadingRef.current.bulkPrint = true;
    notif.open?.({ message: 'Printing receipts...', type: 'progress', key: 'printing-receipts' });
    // Initialize the print jobs
    const printJobs = transactionIds.map((job) => downloadReceipt(job, true));

    for (const job of printJobs) {
      await job;
    }
    downloadLoadingRef.current.bulkPrint = false;
    notif.close?.('printing-receipts');
  };

  const bulkDownloadReceipts = async (transactionIds: string[]) => {
    notif.open?.({
      message: 'Downloading receipts...',
      type: 'progress',
      key: 'download-receipts',
    });
    downloadLoadingRef.current.bulkDownload = true;

    zip.configure({ useWebWorkers: true });

    const links = await Promise.all(
      transactionIds.map(async (transactionId) => {
        const singleUseLink = await getSdk(gqlClient).preparePaymentReceipt({ transactionId });
        // Get single-use link for receipt

        // Validate receipt URL
        if (!singleUseLink.preparePaymentReceipt) throw new Error('Invalid Receipt URL');

        const target = `${backendUrl}/${singleUseLink.preparePaymentReceipt}`;
        return {
          transactionId: transactionId,
          url: target,
        };
      })
    );

    const workingLinks = links.filter((link) => !!link.url);

    const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'));
    await Promise.all(
      workingLinks.map(async (link) => {
        return zipWriter.add(
          `order-receipt-${link.transactionId}.pdf`,
          new zip.HttpReader(link.url)
        );
      })
    );
    const zipBlob = await zipWriter.close();

    const blobUrl = window.URL.createObjectURL(zipBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `order-invoice-${Date.now()}.zip`);
    document.body.appendChild(link);
    link.click();
    downloadLoadingRef.current.bulkDownload = false;

    notif.close?.('download-receipts');
  };

  // Adjust value to be a multiple of 8
  function adjustToMultipleOfEight(value: number) {
    return value % 8 === 0 ? value : value + (8 - (value % 8));
  }
  return {
    downloadInvoice,
    downloadReceipt,
    bulkPrintReceipts,
    bulkDownloadReceipts,
    bulkDownloadLoadingRef: downloadLoadingRef,
  };
};
