import React, { useContext, useState } from 'react';
import { Props } from './props';
import isEmpty from 'lodash/isEmpty';
import { Card } from '@scalingworks/react-admin-ui';
import { BsChevronUp } from 'react-icons/bs';
import startCase from 'lodash/startCase';
import { ChannelContext } from '~/providers/Channel/context';
import { size } from 'lodash';

export const MembershipImportDetailResult: React.FC<Props> = ({ result }) => {
  // ========================= HOOKS
  const { channel } = useContext(ChannelContext);
  const { starTranslation = 'star', pointTranslation = 'point' } =
    channel?.loyaltyTranslations || {};

  // ========================= STATES
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

  // ========================= EVENTS
  const toggleSection = (section: string) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // ========================= VIEWS
  const renderSection = (
    title: string,
    records: any[] | undefined,
    isExpanded: boolean,
    onToggle: () => void,
    renderContent: (record: any) => React.ReactNode
  ) => {
    if (isEmpty(records)) return null;

    return (
      <Card className="w-full mb-4">
        <Card.Header
          className="font-bold cursor-pointer bg-gray-100 hover:bg-gray-200"
          bordered
          onClick={onToggle}
        >
          <div className="flex items-center justify-between">
            <h2 className="text-lg text-error-300">{title}</h2>
            <div className="flex flex-row items-center gap-3">
              <span className='text-error-300'>{size(records)}</span>
              <BsChevronUp
                size={20}
                className={`transition-transform ${isExpanded ? 'rotate-180' : 'rotate-0'} text-error-300`}
              />
            </div>
          </div>
        </Card.Header>
        {isExpanded && (
          <Card.Body className="p-4 space-y-2 bg-gray-50">
            {(records || []).map((record, index) => (
              <div key={index} className="p-2 rounded-md border border-gray-200 bg-white shadow-sm">
                {renderContent(record)}
              </div>
            ))}
          </Card.Body>
        )}
      </Card>
    );
  };

  return (
    <section className="flex flex-col items-center justify-center gap-4 w-full">
      {renderSection(
        'Customers Failed to Import',
        result?.customersFailedImport,
        expandedSections['failedCustomers'] || false,
        () => toggleSection('failedCustomers'),
        (customerRecord) => (
          <p>
            <strong>Row {customerRecord?.rowIndex}:</strong> {customerRecord?.phone}
          </p>
        )
      )}

      {renderSection(
        `Membership ${startCase(starTranslation)} Distribute Failed Records`,
        result?.starDistributeFailedCustomers,
        expandedSections['failedStars'] || false,
        () => toggleSection('failedStars'),
        (customerRecord) => (
          <>
            <p>
              <strong>Customer:</strong> {customerRecord?.title}
            </p>
            <p>
              <strong>Spending:</strong> {customerRecord?.spending}
            </p>
          </>
        )
      )}

      {renderSection(
        `Membership ${startCase(pointTranslation)} Distribute Failed Records`,
        result?.pointDistributeFailedCustomers,
        expandedSections['failedPoints'] || false,
        () => toggleSection('failedPoints'),
        (customerRecord) => (
          <>
            <p>
              <strong>Customer:</strong> {customerRecord?.title}
            </p>
            <p>
              <strong>Spending:</strong> {customerRecord?.spending}
            </p>
          </>
        )
      )}
    </section>
  );
};
