import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GQLClient } from '~/config/gql-client';
import { PrinterContext } from './context';
import { useTranslate } from '@refinedev/core';
import { WebUSBReceiptPrinter } from '@scalingworks/react-admin-ui';
import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';

export const PrinterContextProvider = React.memo((props: any) => {
  const { children } = props;

  // ===================== HOOKS
  const translate = useTranslate();
  const printerLogic = useRef<any | undefined>(null);

  // ===================== STATES
  // TODO: check if possible to save the last used printer locally and loads automatically
  const [printer, setPrinter] = useState<any | null>(null);

  // ===================== VARIABLES
  // not all browsers support web usb api
  const supportWebUsb = !!(navigator as any)?.usb;

  // ===================== EVENTS
  const onSetPrinter = useCallback(
    (printer: any) => {
      setPrinter(printer);

      // any side effects here
    },
    [setPrinter]
  );

  // ===================== EFFECTS
  useEffect(() => {
    if (printerLogic.current || !supportWebUsb) {
      return;
    }
    printerLogic.current = new WebUSBReceiptPrinter();
  }, []);

  useEffect(() => {
    if (!printerLogic.current || !supportWebUsb) {
      return;
    }
    const connectedSub = printerLogic.current?.addEventListener('connected', (device: any) => {
      setPrinter(device);
      let encoder = new ReceiptPrinterEncoder({
        language: device.language,
      });

      let data = encoder.initialize().pulse().encode();
      printerLogic.current.print(data);
    });

    const discountedSub = printerLogic.current?.addEventListener('disconnected', () => {
      setPrinter(null);
    });

    return () => {
      connectedSub?.unsubscribe();
      discountedSub?.unsubscribe();
    };
  }, [printerLogic.current]);

  // ===================== VIEWS
  return (
    <PrinterContext.Provider
      value={{
        printer,
        setPrinter: onSetPrinter,
        printerRef: printerLogic,
      }}
    >
      {children}
    </PrinterContext.Provider>
  );
});
