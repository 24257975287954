import { ReactElement, useState } from 'react';
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { StockLocation } from '~/api';
import { StockLevelForm } from '../StockLevelForm';
import { FiEdit } from 'react-icons/fi';
import { ModalWrapper } from '../ModalWrapper';
import { IconButton } from '@scalingworks/react-admin-ui';
import { StockLevelFormInput } from '../StockLevelForm/props';
import sumBy from 'lodash/sumBy';

export type VariantItemProps = {
  title: string;
  key?: string;
  type: string;
  width?: string;
  prefix?: string;
  render?: (
    field: ControllerRenderProps,
    onChange: (input: { key: string; value: any }) => void
  ) => ReactElement;
};

export const variantFormBuilder = (
  data: { stockLocations: StockLocation[] },
  options?: { isUpdate?: boolean }
): VariantItemProps[] => {
  const { stockLocations } = data;
  const { isUpdate = false } = options || {};

  const mainConfig: VariantItemProps[] = [
    {
      title: 'Image',
      key: 'image',
      type: 'upload',
      width: '15%',
    },
    {
      title: 'Name',
      key: 'name',
      type: 'text',
      width: '25%',
    },
    {
      title: 'Price',
      key: 'price',
      type: 'number',
      width: '20%',
      prefix: 'RM',
    },
    {
      title: 'Quantity',
      key: 'stockLevels',
      type: 'custom',
      width: '10%',
      render: (field, onChange) => {
        const fieldValues = (field.value || []) as StockLevelFormInput[];

        // ====================== STATES
        const [openEdit, setOpenEdit] = useState(false);
        const [stockLevels, setStockLevels] = useState<StockLevelFormInput[]>(fieldValues);

        // ====================== EVENTS
        const onConfirmStockLevels = () => {
          onChange({ key: 'stockLevels', value: stockLevels });
          setOpenEdit(false);
        };

        // ====================== VIEWS
        return (
          <div className="flex flex-col gap-3">
            <span className="flex flex-row items-center justify-between">
              {sumBy(fieldValues, (level) => level.stockOnHand)}
              <IconButton onClick={() => setOpenEdit(true)}>
                <FiEdit size={25} />
              </IconButton>
            </span>
            <ModalWrapper
              title="Stock Location"
              open={openEdit}
              setOpen={setOpenEdit}
              contentClassName="!z-0"
              onConfirm={onConfirmStockLevels}
              // default is z-20, force to remove it in this case due to double modal
            >
              <StockLevelForm
                allStockLocations={stockLocations}
                stockLevels={stockLevels}
                setStockLevels={setStockLevels}
              />
            </ModalWrapper>
          </div>
        );
      },
    },
  ];

  if (isUpdate) {
    mainConfig.push({ title: 'Enabled', key: 'enabled', type: 'switch', width: '10%' });
  }

  return mainConfig;
};

export const renderInvisibleId = ({
  key,
  control,
  defaultValue,
}: {
  key: string;
  control: Control<FieldValues, {}>;
  defaultValue: any;
}) => {
  const idKey = `${key}.id`;
  const controllerProps = {
    control,
    name: idKey,
    defaultValue,
  };
  return (
    <Controller
      {...controllerProps}
      render={() => {
        return <div style={{ display: 'none' }} />;
      }}
    />
  );
};
