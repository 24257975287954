import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import React, { useEffect, useMemo, useRef } from 'react';
import {
  PromoCode,
  RedemptionHistory,
  RedemptionHistoryType as RedemptionType,
  Reward,
} from '~/api';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { ActionButton, ActionButtonRefProps, ActionModalWithTrigger, Loading } from '~/components';
import { dateFormatter, removeUnderscore, toCamelCaseWord } from '~/resources/helpers';
import { FullDateTimeFormat } from '~/config/constant';
import { Checkbox, ImageViewer } from '@scalingworks/react-admin-ui';
import { renderTextWithPrefix } from '~/resources/loyalty/helpers';
import { numeralThousandFormat } from '~/config/helper';
import startCase from 'lodash/startCase';
import { useApiUrl, useCustomMutation, useTranslate, useUpdate } from '@refinedev/core';
import { Action } from '~/components/ActionButton/props';

export const CustomerRedemptionHistory: React.FC<Props> = (props) => {
  const { loading, userId, refetchTrigger } = props;

  // ==================== HOOKS
  const refetchRef = useRef<() => void>(() => null)

  // ==================== EFFECTS
  // Temp solution for refetch
  useEffect(() => {
    refetchRef.current()
  },[ refetchTrigger])

  /**
   * NOTE: This table utilize the redemption resource's
   * data provider overrided config
   */
  const listing = useMemo(() => {
    return createResourceListingPage<RedemptionHistory>({
      showTitle: false,
      customHelmet: 'Customer',
      resourceName: resourceNames.redemptionHistory,
      fields: ['id', 'createdAt', 'isUsed', 'pointCost', 'redeemerRole', 'type', 'usedDate'],
      allowCreate: false,
      allowSearch: false,
      filterConfig: { alwaysExpanded: true },
      defaultPageSize: 5,
      /** TODO:
       * Active rewards should sort by nearest expiry item first.
       * past rewards should be based on most recently used time / expired time.
       */
      defaultSorter: [{ field: 'createdAt', order: 'desc' }],
      extraMeta: {
        userId,
      },
      // TODO: Temporary disabled due to loyalty's api use different api input
      // Instead of `$options` like vendure, need other ways to handle
      // filterControls: {
      //   type: {
      //     // @ts-ignore
      //     type: 'select',
      //     label: 'Type',
      //     operator: 'eq',
      //     config: {
      //       label: 'Type',
      //       placeholder: 'Select type',
      //       // @ts-ignore
      //       options: Object.keys(RedemptionType).map((key) => ({
      //         label: startCase(key),
      //         value: RedemptionType[key as keyof typeof RedemptionType],
      //       })),
      //     },
      //   },
      //   createdAt: {
      //     type: 'daterange',
      //     config: {
      //       label: 'Redeemed at',
      //     },
      //   },
      //   usedDate: {
      //     type: 'daterange',
      //     config: {
      //       label: 'Used at',
      //     },
      //   },
      // },
      columns: ({ refetchData }) => [
        {
          id: 'rewards',
          header: 'Rewards',
          cell: (data) => {
            const index = data.row.index
            const { id, reward, promoCode } = data.row.original;
            const { name, imageUrls } = (reward || {}) as Reward;
            const { name: promoName, imageUrls: promoImages } = (promoCode || {}) as PromoCode;

            useEffect(() => {
              if (index === 1) {
                refetchRef.current = refetchData
              }
            },[])

            return (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <ImageViewer
                  src={imageUrls?.[0] || promoImages?.[0]}
                  className="!w-14 !h-14 rounded-lg object-cover mr-2"
                  alt="Reward"
                />
                <span>{name || promoName}</span>
              </div>
            );
          },
        },
        {
          id: 'type',
          header: 'Type',
          cell: (data) => {
            const { id, type } = data.row.original;
            return (
              <div>
                <span>{toCamelCaseWord(removeUnderscore(type))}</span>
              </div>
            );
          },
        },
        {
          id: 'createdAt',
          header: 'Redeemed at',
          cell: (data) => {
            const { id, createdAt } = data.row.original;
            return (
              <div>
                <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>
              </div>
            );
          },
        },
        {
          id: 'usedAt',
          header: 'Used at',
          cell: (data) => {
            const { id, usedDate } = data.row.original;
            return (
              <div>
                <span>{dateFormatter(usedDate, FullDateTimeFormat)}</span>
              </div>
            );
          },
        },
        {
          id: 'points',
          header: 'Points',
          cell: (data) => {
            const { id, pointCost } = data.row.original;
            return (
              <div>
                <span>
                  {renderTextWithPrefix({
                    prefixText: 'PTS',
                    text: numeralThousandFormat(pointCost),
                    prefixPosition: 'right',
                  })}
                </span>
              </div>
            );
          },
        },
        {
          id: 'actions',
          header: () => <div />,
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const apiUrl = useApiUrl();
            const t = useTranslate();
            const { mutate: markAsUsed } = useCustomMutation();
            const redemptionData = data.row.original;

            const onMarkAsUsed = () => {
              markAsUsed(
                {
                  url: apiUrl,
                  method: 'post',
                  metaData: {
                    fields: ['id'],
                    operation: 'markAsUsed',
                    variables: {
                      input: {
                        type: 'UpdateRedemptionHistoryIsUsedInput!',
                        value: {
                          redemptionHistoryId: redemptionData.id,
                          rewardId: redemptionData.reward?.id,
                          promoCodeId: redemptionData.promoCode?.id,
                          isUsed: 'true',
                        },
                      },
                    },
                  },
                  successNotification: {
                    message: t('customer.loyalty.markAsUsed', {}, 'Successfully marked as used'),
                    type: 'success',
                  },
                  errorNotification: {
                    message: t('customer.loyalty.markAsUsedFailed', {}, 'Failed to update status'),
                    type: 'error',
                  },
                  values: {},
                },
                {
                  onSuccess: () => {
                    refetchData();
                  },
                }
              );
            };

            const actions: Action[] = [];

            if (!redemptionData.isUsed) {
              actions.push({
                label: 'Mark as used',
                name: 'markAsUsed',
                onAction: () => onMarkAsUsed(),
                render: (onAction) => {
                  return (
                    <button onClick={onAction} type="button">
                      Mark as used
                    </button>
                  );
                },
              });
            }

            return <ActionButton ref={actionButtonRef} actions={actions} />;
          },
        },
      ],
    });
  }, [loading, userId]);

  const listingPage = listing();

  // ==================== VIEWS
  if (loading) return <Loading />;
  if (!userId) return null;
  return listingPage;
};
