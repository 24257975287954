import React from 'react';
import { Props } from './props';
import { ModalWrapper } from '~/components';
import { useApiUrl, useCustomMutation, useTranslate } from '@refinedev/core';

export const PayToWinModal: React.FC<Props> = (props) => {
  const { setOpen, customerId, onCompleted } = props;

  // ====================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const { mutate: markPayToWin, isLoading: updating } = useCustomMutation();

  // ====================== EVENTS

  const onConfirm = () => {
    markPayToWin(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'markCustomerAsPay2Win',
          variables: {
            customerId: {
              type: 'ID!',
              value: customerId,
            },
          },
        },
        successNotification: {
          message: 'Upgraded Customer',
          type: 'success',
        },
        errorNotification: {
          message: 'Failed to upgrade customer',
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: () => {
          setOpen(false);
          onCompleted?.();
        },
      }
    );
  };
  // ====================== VIEWS
  return (
    <ModalWrapper
      open
      setOpen={setOpen}
      title="Instant Upgrade"
      onConfirm={onConfirm}
      loading={updating}
    >
      <section className="p-4">
        Upgrade customer to highest tier and distribute the related rewards?
      </section>
    </ModalWrapper>
  );
};
