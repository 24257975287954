import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { resourceNames } from '../resource-names';
import { Channel, MetabaseQuestion, ShippingMethod } from '~/api';
import { useTranslate } from '@refinedev/core';
import { FiPieChart } from 'react-icons/fi';
import { useMetabaseApi } from '~/config/metabase-hook';
import { IframeRefreshButton, Loading, ShowPageWrapper } from '~/components';
import { PropsWithChildren, useContext } from 'react';
import { ChannelContext } from '~/providers/Channel/context';
import { ResourceContext } from '../type';

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.profile,
});

const Reporting: React.FC = () => {
  // ======================= HOOKS
  const t = useTranslate();
  const { useGetMetabaseUrl } = useMetabaseApi();
  const { channel } = useContext(ChannelContext);

  const {
    data: allSalesUrl,
    isLoading: loadingAllSales,
    refetch: refetchAllSales,
  } = useGetMetabaseUrl({
    question: MetabaseQuestion.AllSales,
  });

  const {
    data: attendanceUrl,
    isLoading: loadingAttendance,
    refetch: refetchAttendance,
  } = useGetMetabaseUrl({
    question: MetabaseQuestion.Attendance,
  });

  const {
    data: rawAttendanceUrl,
    isLoading: loadingRawAttendance,
    refetch: refetchRawAttendance,
  } = useGetMetabaseUrl({
    question: '425', // TODO: update to enum
  });

  // ======================= EVENTS
  const refetchAll = () => {
    refetchAllSales();
    refetchAttendance();
    refetchRawAttendance();
  };

  // ======================= VIEWS
  const Row: React.FC<PropsWithChildren & { title?: string }> = (props) => {
    return (
      <div className="flex flex-col space-y-4">
        {props.title && <h3 className="text-xl">{props.title}</h3>}
        {props.children}
      </div>
    );
  };

  return (
    <section className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceNames.report}
        title={t('reporting.name', {}, 'Reporting')}
        showBackButton={false}
        extra={<IframeRefreshButton onClick={refetchAll} />}
      >
        <section className="flex flex-col space-y-8">
          <Row title={t('reporting.section.allSales', {}, 'All Sales')}>
            {loadingAllSales ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={600} src={allSalesUrl?.data as string} />
            )}
          </Row>

          <Row title={t('reporting.section.staffAttendances', {}, 'Staff Attendances')}>
            {loadingAttendance ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={600} src={attendanceUrl?.data as string} />
            )}
          </Row>

          <Row title={t('reporting.section.attendanceRecords', {}, 'Attendance Records')}>
            {loadingRawAttendance ? (
              <Loading />
            ) : (
              <iframe className="w-full" height={450} src={rawAttendanceUrl?.data as string} />
            )}
          </Row>

          {/* TODO: refactor to nested page if adding new reporting,
           don't squeeze too many in one page
            separate into tabs like 'sales' 'orders' 'attendance' etc
           */}
        </section>
      </ShowPageWrapper>
    </section>
  );
};

export const reportingResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames.report,
    label: 'Reporting',
    icon: <FiPieChart />,
    defaultValues: {},
    fields: ['id'],
    columns: () => [],
    allowCreate: false,
    allowDelete: false,
    allowEdit: false,
    allowSearch: false,
    list: {
      render: () => {
        return <Reporting />;
      },
    },
    show: defineShowPage({
      component: () => {
        return <Reporting />;
      },
    }),
  });
};
