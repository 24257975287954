import React, { useState } from 'react';
import { Props, State } from './props';
import { Loading, ModalWrapper, OrderLineProduct } from '~/components';
import { useApiUrl, useCustomMutation, useNotification, useTranslate } from '@refinedev/core';
import { head, isEmpty, pick } from 'lodash';
import {
  AdditionalPropertiesInput,
  FulfillOrderInput,
  FulfillmentMethod,
  FulfillmentMethodInput,
} from '~/api';
import { PhoneField, TextField } from '@scalingworks/react-admin-ui';
import { parsePhoneNumber } from 'libphonenumber-js';

type ForAction = 'ForFulfillment' | 'ForShip';

export const OrderFulfillmentModal: React.FC<Props> = (props) => {
  const {
    open,
    setOpen,
    loadingProducts,
    products,
    isDineInDraft,
    onDeleteLine,
    lines,
    method,
    fulfillmentHandlerCode,
    onCompleted,
    fulfillments,
  } = props;

  // ======================== HOOKS
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const notif = useNotification();

  // ======================== API
  const { isLoading: markLoading, mutate: markAsShipped } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          setOpen(false);
          onCompleted?.();
        }
      },
    },
  });

  const { isLoading: createLoading, mutate: createFulfillment } = useCustomMutation({
    mutationOptions: {
      onSettled: (data) => {
        if (data?.data?.id) {
          setOpen(false);
          onCompleted?.();
        }
      },
    },
  });

  const onMarkAsShipped = async () => {
    const additionalProperties: AdditionalPropertiesInput = {};

    if (driverInfo?.driverContact) {
      const parsed = parsePhoneNumber(driverInfo?.driverContact);
      if (!parsed.isValid()) {
        notif?.open?.({
          message: t('messages.invalidPhoneFormat', {}, 'Invalid phone format'),
          type: 'error',
        });
        return;
      }

      additionalProperties.driverName = driverInfo.driverName;
      additionalProperties.driverContact = parsed.number;
      additionalProperties.courier = driverInfo.courier;
    }

    markAsShipped({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: [
          {
            operation: 'Fulfillment',
            fields: ['id'],
            fragment: true,
          },
        ],
        operation: 'transitionFulfillmentToNewState',
        variables: {
          id: {
            type: 'ID!',
            value: head(fulfillments)?.id,
          },
          state: {
            type: 'FulfillmentState!',
            value: 'Shipped',
          },
          fulfillmentInput: {
            type: 'FulfillmentMethodInput',
            value: {
              method,
              additionalProperties,
              trackingCode: fulfillmentInfo?.trackingCode,
            } as FulfillmentMethodInput,
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.notifications.fulfillment.failed'),
        type: 'error',
      },
      successNotification: {
        message: t('order.notifications.fulfillment.success'),
        type: 'success',
      },
    });
  };

  const onConfirmFulfillment = async () => {
    createFulfillment({
      url: apiUrl,
      method: 'post',
      metaData: {
        fields: [
          {
            operation: 'Fulfillment',
            fields: ['id'],
            fragment: true,
          },
        ],

        operation: 'addFulfillmentToOrder',
        variables: {
          input: {
            type: 'FulfillOrderInput!',
            value: {
              lines: lines?.map((line) => ({
                orderLineId: line.id,
                quantity: line.quantity,
              })),
              handler: {
                arguments: [
                  { name: 'method', value: method },
                  { name: 'trackingCode', value: fulfillmentInfo?.trackingCode || '' },
                ],
                code: fulfillmentHandlerCode,
              },
            } as FulfillOrderInput,
          },
        },
      },
      values: {},
      errorNotification: {
        message: t('order.notifications.fulfillment.failed'),
        type: 'error',
      },
      successNotification: {
        message: t('order.notifications.fulfillment.success'),
        type: 'success',
      },
    });
  };

  const getCurrentState = (): ForAction => (isEmpty(fulfillments) ? 'ForFulfillment' : 'ForShip');

  // ======================== STATES
  const currentState = getCurrentState();
  const [fulfillmentInfo, setFulfillmentInfo] = useState<{ trackingCode: string }>();
  const [driverInfo, setDriverInfo] = useState<{
    driverName?: string;
    driverContact?: string;
    courier?: string;
  } | null>();

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      contentClassName="px-8 py-6"
      onConfirm={currentState === 'ForFulfillment' ? onConfirmFulfillment : onMarkAsShipped}
      title={'Update Order'}
    >
      <section className="py-4">
        {createLoading || markLoading ? (
          <Loading />
        ) : (
          <div>
            {!loadingProducts && !isEmpty(products) && currentState === 'ForFulfillment' && (
              <OrderLineProduct
                canModify={isDineInDraft}
                onDelete={onDeleteLine}
                lineProducts={products}
                lines={lines?.filter((line) => !line.isAddOn)}
              />
            )}

            {currentState === 'ForShip' && method === FulfillmentMethod.Delivery && (
              <div className="flex flex-col w-full py-2 space-y-2">
                <TextField
                  label={t('order.form.courier', {}, 'Courier Name')}
                  onValue={(courier) => setDriverInfo((prev) => ({ ...prev, courier }))}
                  value={driverInfo?.courier}
                  placeholder={t(
                    'order.placeholder.driverCourier',
                    {},
                    `Please enter courier name`
                  )}
                />
                <TextField
                  label={t('order.form.driverName', {}, 'Driver Name')}
                  onValue={(driverName) => setDriverInfo((prev) => ({ ...prev, driverName }))}
                  value={driverInfo?.driverName}
                  placeholder={t('order.placeholder.driverName', {}, `Please enter driver's name`)}
                />
                <PhoneField
                  label={t('order.form.driverContact', {}, 'Driver Contact')}
                  onValue={(driverContact) => setDriverInfo((prev) => ({ ...prev, driverContact }))}
                  value={driverInfo?.driverContact}
                  placeholder={t(
                    'order.placeholder.driverContact',
                    {},
                    `Please enter driver's contact`
                  )}
                />
                <div className="pt-4">
                  <TextField
                    label={t('order.form.trackingCodeIfAny')}
                    onValue={(trackingCode) =>
                      setFulfillmentInfo((prev) => ({ ...prev, trackingCode }))
                    }
                    value={fulfillmentInfo?.trackingCode}
                    placeholder={t('order.placeholder.trackingCodeIfAny')}
                  />
                </div>
              </div>
            )}

            {currentState === 'ForShip' && method !== FulfillmentMethod.Delivery && (
              <div>{t('order.warnings.markAsShipped')}</div>
            )}
          </div>
        )}
      </section>
    </ModalWrapper>
  );
};
