import React, { useContext, useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustom, useTranslate } from '@refinedev/core';
import { Button, IconButton, Skeleton, Tag } from '@scalingworks/react-admin-ui';
import { FiEdit3, FiPlusCircle } from 'react-icons/fi';
import { Membership } from '~/api';
import { getMembershipFields } from '~/config/gql-metadata';
import { format } from 'date-fns';
import { AdjustLoyaltyPoints, ModalWrapper, UpdateLoyaltyValidity, UpdateTier } from '~/components';
import { dateFormatter } from '~/resources/helpers';
import { FullDateFormat, MagicalBeanChannelCode, ValidUntilDateFormat } from '~/config/constant';
import { ChannelContext } from '~/providers/Channel/context';
import { startCase } from 'lodash';
import { AdjustLoyaltyStars } from '~/components/CustomerLoyalty/AdjustStar';
import { PayToWinModal } from './PayToWin';
import dayjs from 'dayjs';

export const MembershipBar: React.FC<Props> = (props) => {
  const { userId, loading, onMutationComplete, customerId, payToWinDate } = props || {};

  // =================== STATES
  const [openUpdateDate, setOpenUpdateDate] = useState(false);
  const [openAdjustPoints, setOpenAdjustPoints] = useState(false);
  const [openAdjustStars, setOpenAdjustStars] = useState(false);
  const [openUpdateTier, setOpenUpdateTier] = useState(false);
  const [openPayToWin, setOpenPayToWin] = useState(false);

  // =================== HOOKS
  const { channel } = useContext(ChannelContext);
  const t = useTranslate();
  const apiUrl = useApiUrl();
  const {
    data,
    isLoading: loadingMembership,
    refetch,
  } = useCustom<Membership>({
    url: apiUrl,
    method: 'get',
    metaData: {
      fields: getMembershipFields,
      operation: 'getMembership',
      variables: {
        userId: {
          value: userId,
          type: 'String!',
        },
      },
    },
  });
  // =================== VARIABLES
  const { starBalance, pointBalance, tierProgress, pointValidUntil } = data?.data || {};
  const { isHighestTier } = tierProgress || {};
  const currentTierId = tierProgress?.currentTier.id;
  const validityDate = pointValidUntil ? new Date(pointValidUntil) : undefined;
  const currentTierName = tierProgress?.currentTier?.name || '';
  const starTranslation = channel?.loyaltyTranslations.starTranslation;
  const pointTranslation = channel?.loyaltyTranslations.pointTranslation;

  // =================== EVENTS
  const refetchAll = () => {
    refetch();
    onMutationComplete?.();
  };

  // =================== VIEWS
  const Row: React.FC<any> = (props) => {
    return (
      <div className="flex flex-row w-full items-center justify-between">{props?.children}</div>
    );
  };
  const Col: React.FC<any> = (props) => {
    return (
      <div className="flex flex-col w-full p-4 px-10 border-b-2 2xl:border-r-2 2xl:border-b-0 2xl:px-4">
        {props?.children}
      </div>
    );
  };
  const Value: React.FC<any> = (props) => {
    return <h3 className="text-white text-xl font-semibold">{props?.children}</h3>;
  };
  const Loading: React.FC = () => <Skeleton heightClass="h-5" width="wide" />;

  return (
    <section className="bg-smoke-900 rounded-lg text-smoke-100 flex flex-col items-center justify-center 2xl:flex-row 2xl:space-x-2">
      {openUpdateTier && (
        <UpdateTier
          userId={userId}
          open
          setOpen={setOpenUpdateTier}
          originalTierId={currentTierId}
          onCompleted={() => {
            refetchAll();
          }}
        />
      )}

      {openAdjustPoints && (
        <AdjustLoyaltyPoints
          pointLabel={startCase(pointTranslation)}
          userId={userId}
          open
          setOpen={setOpenAdjustPoints}
          onCompleted={() => {
            refetchAll();
          }}
        />
      )}

      {openAdjustStars && (
        <AdjustLoyaltyStars
          starLabel={startCase(starTranslation)}
          userId={userId}
          open
          setOpen={setOpenAdjustStars}
          onCompleted={() => {
            refetchAll();
          }}
        />
      )}

      {openUpdateDate && (
        <UpdateLoyaltyValidity
          userId={userId}
          open
          setOpen={setOpenUpdateDate}
          originalDate={validityDate}
          onCompleted={() => {
            refetchAll();
          }}
        />
      )}

      {customerId && openPayToWin && (
        <PayToWinModal
          open
          setOpen={setOpenPayToWin}
          customerId={customerId}
          onCompleted={refetchAll}
        />
      )}
      <Col>
        <Row>
          <div className="flex flex-row items-center gap-3">
            <h3>{t('customer.details.membership.membershipTier', undefined, 'Membership tier')}</h3>
            {channel?.code === MagicalBeanChannelCode && !payToWinDate && !!currentTierName && (
              <Button
                variant="solid"
                className="text-sm"
                size="sm"
                onClick={() => setOpenPayToWin(true)}
              >
                <h3>Instant Upgrade</h3>
              </Button>
            )}
            {!!payToWinDate && (
              <Tag color="purple">
                Instant Upgraded on: {dayjs(payToWinDate).format(FullDateFormat)}
              </Tag>
            )}
          </div>
          {!!currentTierName && (
            <IconButton onClick={() => setOpenUpdateTier(true)}>
              <FiEdit3 className="!text-success-300" size={25} />
            </IconButton>
          )}
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Row>
            <Value>{tierProgress?.currentTier?.name || '-'}</Value>
            {isHighestTier ? (
              <span>{t('customer.details.membership.highest', '(Highest Tier)')}</span>
            ) : tierProgress?.nextTier?.name ? (
              <span className="text-smoke-300">
                {tierProgress?.starToNextTier?.toLocaleString()} {starTranslation}s to{' '}
                {tierProgress?.nextTier?.name}
              </span>
            ) : null}
          </Row>
        )}
      </Col>

      {/* Star Balance */}
      <Col>
        <Row>
          <h3>{startCase(starTranslation)}</h3>
          {/* Only shows when data loaded */}
          {currentTierName && (
            <IconButton onClick={() => setOpenAdjustStars(true)}>
              <FiPlusCircle className="!text-success-300" size={25} />
            </IconButton>
          )}
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Value>{starBalance?.toLocaleString() || 0}</Value>
        )}
      </Col>

      {/* Point Balance */}
      <Col>
        <Row>
          <h3>{startCase(pointTranslation)}</h3>
          {/* Only shows when data loaded */}
          {currentTierName && (
            <IconButton onClick={() => setOpenAdjustPoints(true)}>
              <FiPlusCircle className="!text-success-300" size={25} />
            </IconButton>
          )}
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : (
          <Value>{pointBalance?.toLocaleString() || 0} PTS</Value>
        )}
      </Col>

      {/* Valid Until */}
      <Col>
        <Row>
          {/* <h3>{t(`customer.details.membership.${pointTranslation}ValidUntil`)}</h3> */}
          <h3>
            {startCase(pointTranslation)} {t(`customer.details.membership.validUntil`)}
          </h3>
          {/* Only shows when data loaded */}
          {/* TODO: checks what's this field for, for the membership or points */}
          {/* TODO: check if updating this date manually will cause any other issues */}
          {/* Temporary disable update this date manually, It suppose to follow the cycle */}
          {/* {currentTierName && (
            <IconButton onClick={() => setOpenUpdateDate(true)}>
              <FiEdit3 className="!text-success-300" size={25} />
            </IconButton>
          )} */}
        </Row>
        {loadingMembership || loading ? (
          <Loading />
        ) : validityDate ? (
          <Value>{dateFormatter(validityDate, ValidUntilDateFormat)}</Value>
        ) : (
          '-'
        )}
      </Col>
    </section>
  );
};
