import { Button, Dialog, SelectField, TextField } from '@scalingworks/react-admin-ui';
import React, { useState } from 'react';
import { Props } from './props';
import { useApiUrl, useCustom, useCustomMutation, useTranslate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { MembershipTier, MembershipTierList, UpdateMembershipInput } from '~/api';

export const UpdateTier: React.FC<Props> = (props) => {
  const { open, setOpen, userId, originalTierId = '', onCompleted } = props;

  // ==================== HOOKS
  const apiUrl = useApiUrl();
  const t = useTranslate();

  // ==================== STATES
  const [tierId, setTierId] = useState(originalTierId);

  // ==================== API
  const { data: tiers } = useCustom<MembershipTierList>({
    method: 'get',
    url: apiUrl,
    metaData: {
      fields: [{ items: ['id', 'name'] }] as ResourceField<MembershipTierList>[],
      operation: 'getMembershipTiers',
    },
  });

  const { mutate: updateTier, isLoading: updating } = useCustomMutation();

  const onUpdate = async () => {
    updateTier(
      {
        url: apiUrl,
        method: 'post',
        metaData: {
          fields: ['id'],
          operation: 'updateMembership',
          variables: {
            input: {
              type: 'UpdateMembershipInput!',
              value: {
                userId,
                tierId,
              } as UpdateMembershipInput,
            },
          },
        },
        successNotification: {
          message: t('customer.loyalty.tierUpdated'),
          type: 'success',
        },
        errorNotification: {
          message: t('customer.loyalty.tierUpdateFailed'),
          type: 'error',
        },
        values: {},
      },
      {
        onSuccess: () => {
          setOpen(false);
          onCompleted?.();
        },
      }
    );
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content className="fixed top-1/3 lg:w-1/2">
          <Dialog.Title>
            {t('customer.loyalty.updateMembershipTier', {}, 'Update Membership Tier')}
          </Dialog.Title>
          <div className="p-4 flex flex-col gap-4">
            <SelectField
              label={t('customer.loyalty.tier', {}, 'Tier')}
              placeholder={t('customer.loyalty.selectTier', {}, 'Select tier')}
              value={tierId}
              onValue={(tier) => setTierId(tier)}
              options={
                tiers?.data?.items?.map((tier: MembershipTier) => ({
                  label: tier.name,
                  value: tier.id,
                })) || []
              }
            />
          </div>

          <div className="flex flex-row justify-end space-x-4">
            <Button variant="default" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={!tierId || tierId === originalTierId}
              loading={updating}
              variant="solid"
              onClick={onUpdate}
            >
              {t('common.confirm', {}, 'Confirm')}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
