import React, { useState } from 'react';
import { Props, StockLevelFormInput } from './props';
import { Button, NumberInput } from '@scalingworks/react-admin-ui';
import { StockLocation } from '~/api';
import { difference, isEmpty } from 'lodash';
import { ModalWrapper } from '../ModalWrapper';

export const StockLevelForm: React.FC<Props> = (props) => {
  const { allStockLocations = [], stockLevels, setStockLevels } = props;

  // ===================== STATES
  const [openLocationSelection, setOpenLocationSelection] = useState(false);

  // ===================== VARIABLES
  const allStockLocationIds = allStockLocations.map((location) => location.id);
  const assignedLocationIds = stockLevels.map((level) => level.stockLocationId);
  const availableLocationIds = difference(allStockLocationIds, assignedLocationIds);

  // ===================== EVENTS
  const onAssignLocation = (stockLocation: StockLocation) => {
    setStockLevels((prev) => [
      ...prev,
      { stockLocationId: stockLocation.id, stockLocationName: stockLocation.name, stockOnHand: 0 },
    ]);

    setOpenLocationSelection(false);
  };

  const onEditStockLevel = (stockLevelInput: StockLevelFormInput) => {
    setStockLevels((prev) =>
      prev.map((level) =>
        level.stockLocationId === stockLevelInput.stockLocationId
          ? { ...level, ...stockLevelInput }
          : level
      )
    );
  };

  // ===================== VIEWS
  return (
    <div>
      {/* TODO: Infobox */}
      {isEmpty(stockLevels) && (
        <p className="text-gray-400">Assign the stock available for different outlet</p>
      )}
      <section className="flex flex-col gap-3 my-3">
        {stockLevels.map((level) => {
          return (
            <div className="flex flex-row justify-between items-center w-full">
              <span>{level.stockLocationName}</span>
              <div className="w-1/4">
                <NumberInput
                  defaultValue={level.stockOnHand}
                  value={level.stockOnHand.toString()}
                  onValue={(value) => onEditStockLevel({ ...level, stockOnHand: Number(value) })}
                />
              </div>
            </div>
          );
        })}
      </section>

      {!isEmpty(availableLocationIds) && (
        <Button className="my-4 w-full" onClick={() => setOpenLocationSelection(true)}>
          Assign Location
        </Button>
      )}

      {/* Modal to assign new Stock Location */}
      <ModalWrapper
        title="Select stock locations"
        open={openLocationSelection}
        setOpen={setOpenLocationSelection}
      >
        <div className="flex flex-col gap-3 my-4">
          {allStockLocations
            .filter((location) => availableLocationIds.includes(location.id))
            .map((location) => {
              return <Button onClick={() => onAssignLocation(location)}>{location.name}</Button>;
            })}
        </div>
      </ModalWrapper>
    </div>
  );
};
